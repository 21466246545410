import Product from '../component/product/products'
import React, { useEffect, useState, useCallback } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { toAbsoluteUrl } from '../AssetHelpers'
import Banner from '../component/general/banner';
import BreadcrumbComp from '../component/general/breadcrumb';
import { callApiWithGenerateToken } from '../api/api'
import Loading from '../component/Loading';
import VerticalTabsWithSubTabs from '../component/general/verticalTabsWithSubTabs';
var config = require('../Config2.js')

function Products() {

  const img = toAbsoluteUrl("/media/img/bg-about.png")
  const imgBanner = toAbsoluteUrl("/media/img/bannerProductInfo.png")

  const [bannerImg, setBannerImg] = useState('');
  const [page, setPage] = React.useState(1);
  const [category, setCategory] = useState('');
  const [items, setItems] = useState('');
  const [parent, setParent] = useState('');
  const [countPage, setCountPage] = useState('');
  const [loading, setLoading] = useState(0);
  const [breadcrumArr, setBreadcrumArr] = useState([
    {
      label: 'المنتجات',
      link: ''
    },
  ]);
  const logResult = useCallback(() => {
    return 2 + 2;
  }, []); //logResult is memoized now.

  useEffect(() => {
    getData(0, 1)
  }, [logResult]);

  const getData = async (parent = 0, pageNum = page) => {
    let apiData = {
      type: 'getProducts',
      page: pageNum,
      limit: 6,
      lang: '1',
      parent: parent,
      getCat: '1',
    }
    setLoading(0)

    var categoryData = await callApiWithGenerateToken(config.DOMAIN, apiData, 'POST');
    if (categoryData) {
      setPage(pageNum)
      setParent(parent)
      setBannerImg(categoryData.bannerInfo)
      setCategory(categoryData.category)
      setItems(categoryData.products)
      setCountPage(categoryData.entries.page)
      setLoading(1)

    }
  }
  const changePage = (event, value) => {
    getData(parent, value);

    setPage(value);
  };

  return (
    <div className='bg-img' style={{ backgroundImage: `url(${img})` }}>
      <div className='position-relative'>
        <Banner img={bannerImg?.image ? bannerImg.image : imgBanner} />
        <BreadcrumbComp breadcrumData={breadcrumArr} />
      </div>
        <Container>
        {category != null && category != undefined && category != '' ?
            <Row>
              <Col md={12}>
                <VerticalTabsWithSubTabs loading={loading} tab={category} items={items} getData={getData} changePage={changePage} page={page} countPage={countPage} />
              </Col>
            </Row>
       
        : <Loading />
      }
        </Container>
{/* ==========================
        <Product/> */}
    </div>

  );
}
export default Products;

