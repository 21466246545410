import React, { useState,useEffect } from 'react';
import { PhoneIphone, Facebook, LocationOn, Email, Instagram, WhatsApp, Twitter,PhonelinkRing } from '@mui/icons-material';
import { Row, Col, Container, Form, FormGroup, Input } from 'reactstrap';
import bkgpage5 from "../../bkgpage5.png"
import Spinar from "../../spinar.gif"
import { NavLink } from 'reactstrap';
import { callApiWithGenerateToken } from '../../api/api'
import PhoneInput from 'react-phone-number-input'
import '../../../node_modules/react-phone-number-input/style.css'

var config = require('../../Config2.js')

function ContactUs(props) {
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showErrorMessage , setShowErrorMessage] = useState(false);
  const [errorTextMessage , setErrorTextMessage] = useState('');

  const logged = localStorage.getItem("token");
  useEffect(() => {
    // getData()
    if (logged) {
      setName(localStorage.getItem("fname"));
      setLname(localStorage.getItem("lname"));
      setPhone(`+962 ${localStorage.getItem("phone")}`);
      setEmail(localStorage.getItem("email"));
    }
  }, []);
  async function submitSend(event) {
    event.preventDefault();
    setIsOpen(true)


    var response = await callApiWithGenerateToken(config.DOMAIN, { type: 'submitContactUs', lang: 1, fname: name, lname: lname, email: email, phone: phone, msg: message }, 'POST');
    if (response.msg == 'Added') {
      setPhone('')
      setName('')
      setEmail('')
      setLname('')
      setMessage('')
      setShowMessage(true)
      setShowErrorMessage(false)
      setErrorTextMessage('')
    }else if(response.code==201){
      setShowErrorMessage(true)
      setShowMessage(false)
      setErrorTextMessage(response.msg)
    }
    setIsOpen(false)


  }

  return (
    <div className='contact-us-container'>
      {/* {isOpen  ? <LoadingModal /> : null} */}
      <Container>
        <Row className='mb-5'>
          <Col md={12}>
            <div className='d-flex align-items-center'>
              <PhonelinkRing className='icon-page PhonelinkRingIcon icon-size' />
              <div className='title-page px-2'>تواصل معنا</div>
            </div>
          </Col>
        </Row>

        <Row className='borderRadius-22px' >
          <Col lg='12' xl={11} xxl={12} className='borderRadius-22px backgColor-303050 py-5 px-4'>
            <p className='contactus-lable'>ادخل بياناتك الشخصية للتواصل معنا  </p>
            <Form onSubmit={(event) => submitSend(event)}>
              <Row style={{ borderBottomRightRadius: 22, borderBottomLeftRadius: 22 }}>
                <Col lg={8} xxl={9} xs='12' >
                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup className='text-input'>
                        <Input
                          required={1}
                          id="exampleEmail"
                          name="email"
                          placeholder="الاسم الاول"
                          title="الاسم الاول"
                          type="text"
                          size={1}
                          value={name}
                          onChange={(e) => setName(`${e.target.value}`)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup className='text-input'>
                        <Input
                          required={1}
                          id="examplePassword"
                          name="password"
                          placeholder="الاسم الاخير"
                          title="الاسم الاخير"
                          type="text"
                          value={lname}
                          onChange={(e) => setLname(`${e.target.value}`)}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6} xs={12}>
                      <FormGroup className='text-input'>
                        <Input
                          required={1}
                          id="exampleEmail"
                          name="email"
                          placeholder="البريد الالكتروني"
                          title="البريد الالكتروني"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(`${e.target.value}`)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup className='text-input show-ltr'>
                        <PhoneInput
                          required={1}
                          className='PhoneInput'
                          international
                          defaultCountry="JO"
                          placeholder="رقم الهاتف"
                          title="رقم الهاتف"
                          value={phone}
                          onChange={(e) => setPhone(e)} />
                      </FormGroup>
                    </Col>

                  </Row>

                  <Row >
                    <Col md={12} xs={12} >
                      <FormGroup className='text-message-input'>
                        <Input
                          required={1}
                          id="exampleEmail"
                          name="email"
                          placeholder="الرسالة"
                          type="textarea"
                          rows='8'
                          value={message}
                          onChange={(e) => setMessage(`${e.target.value}`)}
                        />
                      </FormGroup>
                    </Col>

                    <Col className='d-flex' >
                      <button disabled={isOpen} className='btn btn-white'>
                        ارسال
                      </button>
                    </Col>

                    <Col md={1} xs={10}>
                      {isOpen && <img className='spinar-loading' src={Spinar} />}
                    </Col>

                    <Col md={7} xs={10}>
                      {showMessage && <p className='message-text'>تم الارسال بنجاح</p>}
                      {showErrorMessage && <p className='message-text'>{errorTextMessage}</p>}
                      
                    </Col>

                  </Row>

                </Col>

                <Col lg={4} xxl={3} xs='12' className='borderRadius-22px mt-4 mt-lg-0'>
                  <FormGroup className='contact-info-main'>

                    <div className='contact-info-box'>
                      {/* Instagram, WhatsApp, Twitter */}
                      <div className='contact-info-lable'>معلومات التواصل</div>

                      {props.data.moreFields['1_Text_1_4'] ? <Row md='12' xs='12' className='contact-line'>
                        <Col md='1' xs='1'><LocationOn className='icone-contact' /></Col>
                        <Col md='9' xs='10'><div className='text-information'>{props.data.moreFields['1_Text_1_4']}</div> </Col>
                      </Row> : null}

                      {props.data.moreFields['2_Text_2_4'] ? <Row md='12' xs='12' className='contact-line'>
                        <Col md='1' xs='1'><Email className='icone-contact' /></Col>
                        <Col md='9' xs='10'><div className='text-information'>{props.data.moreFields['2_Text_2_4']}</div> </Col>
                      </Row> : null}

                      {props.data.moreFields['3_Text_3_4'] ? <Row md='12' xs='12' className='contact-line '>
                        <Col md='1' xs='1'><PhoneIphone className='icone-contact' /></Col>
                        <Col md='9' xs='10'><div className=' show-ltr text-information'>{props.data.moreFields['3_Text_3_4']}</div> </Col>
                      </Row> : null}

                      <Row className='marginTop-19px' style={{ marginTop: 19 }}>
                        {props.data.moreFields['6_Text_6_4'] &&
                          <Col xs={2} sm={1} lg={2}>
                            <NavLink target={'_blank'} href={props.data.moreFields['6_Text_6_4']} className="inactive" activeClassName="active" exact={true}>
                              <Instagram className='icone-contact' />
                            </NavLink>
                          </Col>}
                        {props.data.moreFields['4_Text_4_4'] &&
                          <Col  xs={2} sm={1} lg={2}>
                            <NavLink target={'_blank'} href={props.data.moreFields['4_Text_4_4']} className="inactive" activeClassName="active" exact={true}>
                              <Facebook className='icone-contact' />
                            </NavLink>
                          </Col>}
                        {props.data.moreFields['7_Text_7_4'] &&
                          <Col xs={2} sm={1} lg={2}>
                            <NavLink target={'_blank'} href={props.data.moreFields['7_Text_7_4']} className="inactive" activeClassName="active" exact={true}>
                              <WhatsApp className='icone-contact' />
                            </NavLink>
                          </Col>}
                        {props.data.moreFields['5_Text_5_4'] &&
                          <Col xs={2} sm={1} lg={2}>
                            <NavLink target={'_blank'} href={props.data.moreFields['5_Text_5_4']} className="inactive" activeClassName="active" exact={true}>
                              <Twitter className='icone-contact' />
                            </NavLink>
                          </Col>}
                        <Col>
                        </Col>
                      </Row>
                    </div>


                  </FormGroup>
                </Col>

              </Row>
            </Form>

          </Col>


        </Row>
      </Container>
    </div>

  );
}

export default ContactUs;
