
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Text from '../general/text';
import Title from '../general/title';


function GoalUs(props) {
    const { goal, childGoal } = props
    const iconGoal = () => {
        if (childGoal != null && childGoal != undefined && childGoal != '') {
            return childGoal.map((rowData, index) => {
                return (
                    <Col sm={4} lg={3} key={index} className="mt-5 mb-3 ">
                        <div className='text-center '>
                            <div className='icon-goal'>
                                <img src={rowData.image} className="img-fluid" alt="" />
                            </div>
                            <div>
                                <strong dangerouslySetInnerHTML={{ __html: rowData.description }} />
                                <p>{rowData.name}</p>
                            </div>
                        </div>
                    </Col>
                )
            })

        }
    }

    return (
        <Container className='container-goal'>
            <Row>
                <Col lg={6} className="px-4 mt-4">
                    <Text type={'tagHtml'} text={goal['description']} />
                    <Row className='mt-5 mb-4'>
                        {iconGoal()}
                    </Row>
                </Col>
                <Col lg={6} className="px-0 img-goal">
                    <img src={goal['image']} className="img-fluid" alt="" />
                </Col>
            </Row>
        </Container>
    );
}

export default GoalUs;
