if (window.location.hostname == 'localhost') {
 
  var _Environments = {
  
    DOMAIN: 'https://esport.jo/API/data.php?',
    // DOMAIN: 'https://dev72.hoja-crm.com/API/data.php?',
  
};

} else {

  var _Environments = {
    DOMAIN: `https://${window.location.hostname}/API/data.php?`,
};

}

  module.exports = _Environments;
  

// if (window.location.hostname == 'localhost' || window.location.hostname == 'dev44.hoja-crm.com' || window.location.hostname == 'dev.easypay-card.com') {
//     // window.HTTP_ROOT_PATH = 'https://dev45.hoja-crm.com/API/';
//     window.HTTP_ROOT_PATH = 'https://cp8.easypay-card.com/API/';
//     // window.HTTP_ROOT_PATH = 'https://stg.easypay-card.com/API/';
// }else if(window.location.hostname == 'dev43.hoja-crm.com'){
//     // window.HTTP_ROOT_PATH = 'https://cp-stg.easypay-card.com/API/';
//     window.HTTP_ROOT_PATH = 'https://cp8.easypay-card.com/API/';

    
// } else {
//     // window.HTTP_ROOT_PATH = 'https://beta.easypay-card.com/API/'
//     window.HTTP_ROOT_PATH = 'https://cp.easypay-card.com/API/' 
// }

// if(window.location.hostname == 'user.jsc.com.jo'){
//     window.MAIN_IMAGE='_1'
// }else{
//     window.MAIN_IMAGE='' 
// }

// // if(window.location.hostname == 'dev44.hoja-crm.com' || window.location.hostname == 'localhost'){
// //     window.MAIN_IMAGE='' 
// // }else{
// //     window.MAIN_IMAGE='_1' 
// // }

// window.HTTP_SUP_PATH = 'https://' + window.location.hostname + '/react/'
// window.API_PERM_USER = window.HTTP_ROOT_PATH + "permUser.php";
// window.API_DAFTRA_API= window.HTTP_ROOT_PATH + "daftra_Api.php";


// window.AUTH = 'Basic dHVhOnR1YXR1YQ=='
// // window.AUTH = 'Basic '+btoa('tua:tuatua')

// // window.AUTH = 'Bearer dHVhOnR1YXR1YQ=='
// // window.AUTH = 'Bearer '+btoa('tua:tuatua')

//// test 

// test

window.TOKEN = '8fdd11736e99a1258deca51f06847962';
window.USERID = 440;