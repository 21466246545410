import React, { useCallback, useState, useEffect } from 'react';
import MapWrapper from '../component/ccontactUs/MapWrapper';
import ContactUs from '../component/home/contactUs'
import {callApiWithGenerateToken} from '../api/api'
import '../App.css';
import { toAbsoluteUrl } from '../AssetHelpers'

var config = require('../Config2.js')   

export default function App() {
  const img = toAbsoluteUrl("/media/img/bg-about.png")

  const [contactUsData, setContactUsData] = useState('');
  const [location, setLocation] = useState('');
   
  const logResult = useCallback(() => {
    return 2 + 2;
  }, []); //logResult is memoized now.

  useEffect(() => {
    getData()
    },[logResult]);

    const getData = async()=>{
      var data = await callApiWithGenerateToken(config.DOMAIN, {type: 'getContactusPageData', lang:1}, 'POST');
        if(data){
          setContactUsData(data)
          setLocation(data.moreFields['8_Map_8_4'])
        }
      }

  return (
    <div style={{ marginBottom: 100, backgroundColor: '#1e1e34', backgroundImage: `url(${img})` }}>
      <div style={{position: 'relative'}}>
      {/* <div className='gradient-top-contactP'></div> */}
      {location && <MapWrapper data={location}/>}
      </div>
      {contactUsData && <ContactUs data={contactUsData}/>}
    </div>
  );
}
