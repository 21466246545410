import React, { useEffect, useState, useCallback } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { toAbsoluteUrl } from '../AssetHelpers'
import Banner from '../component/general/banner';
import BreadcrumbComp from '../component/general/breadcrumb';
import Title from '../component/general/title';
import { callApiWithGenerateToken } from '../api/api'
import LatestNews from '../component/news/latestNews';
import VerticalTabs from '../component/general/verticalTabs';
import Loading from '../component/Loading';
import '../styles/productInfo.css'
var config = require('../Config2.js')
function News() {
  const img = toAbsoluteUrl("/media/img/bg-about.png")
  const imgBanner = toAbsoluteUrl("/media/img/bannerProductInfo.png")

  const [breadcrumArr, setBreadcrumArr] = useState([{
    label: 'الأخبار',
    link: ''
  }])
  const [items, setItems] = useState([])
  const [bannerImg, setBannerImg] = useState('');
  const [category, setCategory] = useState([])
  const [latest, setLatest] = useState([])
  const [pageActive, setPageActive] = useState(1)
  const [parent, setParent] = useState(0)
  const [flagButton, setFlagButton] = useState(0);
  const [loading, setLoading] = useState(0);


  const logResult = useCallback(() => {
    return 2 + 2;
  }, []); //logResult is memoized now.

  useEffect(() => {
    getData(parent, pageActive, '1')
  }, [logResult]);


  const getData = async (parent = 0, page = pageActive, latest = 0) => {
    let apiData = {
      type: 'getNews',
      page: page,
      limit: 6,
      limitLatest: '5',
      lang: '1',
      parent: parent,
      getCat: '1',
      latest: latest,
    }
    setLoading(0)

    var news = await callApiWithGenerateToken(config.DOMAIN, apiData, 'POST');
    if (news) {
      setBannerImg(news.bannerInfo)
      setPageActive(page)
      setCategory(news.category)
      setParent(parent)
      setFlagButton(news.entries.page)
      if (page == '1') {
        setItems(news.data)
      } else {
        setItems([...items, ...news.data])
      }
      setBreadcrumArr(breadcrumArr)
      if (latest == '1') {
        setLatest(news.latest)
      }
      setLoading(1)

    }
  }

  const changePage = () => {
    getData(parent, pageActive + 1, '0')
  }

  return (
    <div className='bg-img' style={{ backgroundImage: `url(${img})` }}>

      {latest != null && latest != undefined && latest != '' || category != null && category != undefined && category != '' ?
        <>
          <div className='position-relative'>
            <Banner img={bannerImg?.image ? bannerImg.image : imgBanner} />
            <BreadcrumbComp breadcrumData={breadcrumArr} />
          </div>
          <Container>
            {latest != null && latest != undefined && latest != '' &&
             <> 
             <Title title={'آخر الاخبار'} />
                <LatestNews  latest={latest} /></>
            }
            {category != null && category != undefined && category != '' &&
              <div className='mt-5'>
                <Title title={'المزيد من الاخبار'} />
                <Row>
                  <Col md={12}>
                    <VerticalTabs loading={loading} tab={category} items={items} getData={getData} changePage={changePage} flagButton={flagButton} page={pageActive} />
                  </Col>
                </Row>
              </div>
            }
          </Container>
        </>
        : <Loading />
      }
    </div>

  );
}

export default News;
