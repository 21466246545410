import React, { useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import imgNotFound from "../../imgNotFound.png"
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import { ArrowBackIosNewOutlined, ExtensionRounded } from '@mui/icons-material';
import Text from '../general/text';

function products(props) {


  return (

    <Container className='Home-welcome-section'>
      <Row>

        <Col md='6' xs='6' sm='6' className='paddingBottom-50 '>
          <Row md='12' xs='6' sm='6' className='' style={{ alignItems: 'center', float: 'right' } }>
            <div><ExtensionRounded fontSize="large" className='icon-page' /></div>
            <div className='title-page-blog-product'>منتجات</div>
          </Row>
        </Col>



        <Col md='6' xs='6' sm='6'>

          <Link className="footer-labils-box" to='/Product'>
            <Row >
              <div className='product-text'>
                عرض الكل
                <ArrowBackIosNewOutlined />
              </div>
            </Row>
          </Link>

        </Col>
      </Row>
      {props.data[0] &&
        <Row className='index-pro index-pro-h-main'>
          <Col md={5} lg={3} className="text-center">
            <img src={props.data[0].image ? props.data[0].image : imgNotFound} className="img-fluid" />
          </Col>
          <Col md={7} lg={9}>
            <Text type={'tagHtml'} text={props.data[0].name} bold={'700'} />
            { props.data[0]['moreFields']['12_Text_12_5'] ?  <Text type={'tagHtml'} text={`${props.data[0]['moreFields']['12_Text_12_5']} JD`} bold={'700'}  /> : ""}
            <Text type={'tagHtml'} text={props.data[0].description} numberLetter={500} />
            <div className="text-start">
              <Link to={'/productInfo/' + props.data[0].id} exact={true}>
                <button className='btn btn-card'>
                  عرض المزيد
                </button>
              </Link>
            </div>
          </Col>
        </Row>
      }
      <Row>
        {props.data[1] &&
          <Col lg={8}>
            <Row className='index-pro index-pro-h-sub'>
              <Col md={7} xl={8}>
                <Text type={'tagHtml'} text={props.data[1].name} bold={'700'} />
                { props.data[1]['moreFields']['12_Text_12_5'] ?  <Text type={'tagHtml'} text={`${props.data[1]['moreFields']['12_Text_12_5']} JD`} bold={'500'}  /> : ""}
                <Text type={'tagHtml'} text={props.data[1].description} numberLetter={300} />
                <div>
                  <Link to={'/productInfo/' + props.data[1].id} exact={true}>
                    <button className='btn btn-card'>
                      عرض المزيد
                    </button>
                  </Link>
                </div>
              </Col>

              <Col md={5} xl={4} className="text-center">
                <div className='index-img-pro_1'>
                  <img src={props.data[1].image ? props.data[1].image : imgNotFound} className="img-fluid w-100 h-100" />
                </div>
              </Col>
            </Row>
          </Col>
        }
        {props.data[2] &&
          <Col lg={4} className='pe-lg-4 pe-xl-5'>
            <Row className='index-pro index-pro-h-sub'>
              <Col md={12}>
                <Text type={'tagHtml'} text={props.data[2].name} bold={'700'} />
                { props.data[2]['moreFields']['12_Text_12_5'] ?  <Text type={'tagHtml'} text={`${props.data[2]['moreFields']['12_Text_12_5']} JD`} bold={'500'}  /> : ""}
                <Text type={'tagHtml'} text={props.data[2].description} numberLetter={150} />
              </Col>
              <Col md={12}>
                <Row>
                  <Col lg={8} xxl={6}>
                    <div>
                      <Link to={'/productInfo/' + props.data[2].id} exact={true}>
                        <button className='btn btn-card min-w-auto'>
                          عرض المزيد
                        </button>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={4} xxl={6} className="text-center pb-3 pb-lg-0">
                    <div className='index-img-pro_2'>
                      <img src={props.data[2].image ? props.data[2].image : imgNotFound} className="img-fluid w-100 h-100" />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        }
      </Row>

     



    </Container>

  );
}

export default products;

