
import React from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import Text from '../general/text';
import imgNotFound from "../../imgNotFound.png"
import { Link } from 'react-router-dom';
import Title from '../general/title';

import { toAbsoluteUrl } from '../../AssetHelpers'
import "../../styles/productInfo.css"


function ProductCard(props) {
    const { title, description, numberLetters, image, star, leftStar, fullContent, imgH, id, wHight, price } = props;
    const img_not_found = toAbsoluteUrl("/media/img/not_found.png")
    return (
        <Row className={wHight ? 'mb-3' : 'mb-4'}>
            <Col md={12}>
                <Card className='itemCard '>
                    {fullContent == '0' &&
                        <CardBody className={imgH == 'imgH' ? 'imgH position-relative text-center' : 'imgPro position-relative text-center'}>
                            <img src={props?.image ? image : img_not_found} className="img-fluid " alt="" />
                            {/* {props?.number && <div className={leftStar == true ? 'container-star star-dir-ltr' : 'container-star'}>
                                <StarRoundedIcon className='color-yellow' /> <span>{star}</span>
                            </div>
                            } */}
                        </CardBody>
                    }

                    {fullContent == '1' &&
                        <CardBody className={wHight ? 'card-fullContent-blog subItemCard' : 'card-fullContent ProductCardInfo subItemCard'}>
                            <div className='mt-2'>
                                <h5 className={wHight ? 'color-white text-align-right' : 'color-white'}>{title.substring(0, 35) + "..."}</h5>


                                {wHight ? <div className='body-text-produc text-align-right' dangerouslySetInnerHTML={{ __html: description.substring(0, 30) }} />
                                    : <Text line={"line-no"} text={description.substring(0, 110) + "..."} col type="tagHtml" />
                                }

                            </div>
                            <div className={wHight ? 'text-center imgRelatedH box-img-product ' : 'position-relative text-center imgRelatedH '}>
                                <img src={image ? image : imgNotFound} className={wHight ? "img-fluid pading-img-card img-fluid" : "pading-img-card img-fluid"} alt="" />
                                {/* {props?.number && <div className={'container-star'}>
                                    <StarRoundedIcon className='color-yellow' /> <span>{number}</span>
                                </div>} */}
                            </div>
                            <div className={wHight ? 'btn-product-card ' : ''}>
                                <Row>
                                    <Col md={8}>
                                        <Link className='btn btn-card float' to={'/productInfo/' + id}>عرض المزيد</Link>
                                    </Col>
                                    <Col md={4}>
                                        <div className='end price-productsInfo' >
                                          {price ? ` ${price} JD`: ''}
                                        </div>
                                    </Col>
                                </Row>

                            </div>

                        </CardBody>
                    }
                </Card>
            </Col>
        </Row>
    );
}

export default ProductCard; 
