import React, { useState } from 'react';
import playicon from "../../playicon.png"
import posterimg from "../../posterimg.png"

function Videoa(props) {

  const [isPlay, setIsPlay] = useState(1);
  const playVideo = () => {
    setIsPlay(0)
  }


  return (
    <div className='video-box mt-4 mt-lg-0'>
      {isPlay > 0 ?
        <div className='image-poster' style={{ backgroundSize: 'cover', backgroundImage: props.image ? `url(${props.image})` : `url(${posterimg})` }}>
          <img className='icon-play-video' src={playicon} alt="Logo" onClick={() => playVideo()} />
        </div>
        :
        <iframe src={`https://www.youtube.com/embed/${props.data}?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&controls=0&autoplay=1`} width="100%" height="390" frameborder="0" allowfullscreen></iframe>}
    </div>

  );
}
export default Videoa;