import { ArrowBackIosNewOutlined, LibraryBooks } from '@mui/icons-material';
import imgNotFound from "../../imgNotFound.png"
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Row, Col, Container } from 'reactstrap';
import Slider from "react-slick";
import Text from '../general/text';


function blog(props) {


  const FuncScroll = ({ slide }) => {
    return (

      <div className='item-scroll text-end' key={slide.id}>
        <img className='item-scroll-image img-fluid' src={slide.image ? slide.image : imgNotFound} alt="cover" />
        <div className='text-lable-item-scroll'>
          <Text type={'tagHtml'} text={slide.name} />
          <Text type={'tagHtml'} text={slide.description} numberLetter={150} />
          <div className='button-box-item-scroll'>
            <Link to={'/content/' + slide.id + '/' + slide.categoryId} exact={true}>
              <button className='btn btn-outline-white'>
                عرض المزيد
              </button>
            </Link>
          </div>
        </div>

      </div>
    )
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1010,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (

    <div>
      <div className='blog-box'>
        <Container>
          <Row xs="4" >






            <Col md='6' xs='6' className='paddingBottom-50'>
              <Row md='12' xs='6' style={{ alignItems: 'center', float: 'right' }}>
                <div><LibraryBooks fontSize="large" className='icon-page' /></div>
                <div className='title-page-blog-product'>المدونة</div>
              </Row>
            </Col>






            <Col md='6' xs='6'  >
              <Link className="footer-labils-box" to='/Blog'>
                <Row >
                  <div className='product-text'>
                    عرض الكل
                    <ArrowBackIosNewOutlined />
                  </div>
                </Row>
              </Link>

            </Col>
          </Row>
        </Container>
      </div>


      <Container className='mainn'>
        <Row xs="1" className='alignItems-justifyContent'>
          <Col md='12' xs="10" className='scroll-slid-arow'>
            <Slider {...settings}>
              {props.data.map(function (slide) {
                return (
                  <FuncScroll slide={slide} />
                );
              })}
            </Slider>
          </Col>

        </Row>
      </Container>
    </div>
  );
}

export default blog;
