import React, { useEffect, useState, useCallback } from "react";
import { Col, Row, FormGroup, FormFeedback } from "reactstrap";
import Form from "react-bootstrap/Form";
import Text from "../general/text";
import { Button } from "react-bootstrap";
import { callApi, callApiWithGenerateToken, callAsyncAPI } from "../../api/api";
import { Link } from "react-router-dom";
import { Message } from "@mui/icons-material";

var config = require("../../Config2.js");

export default function NewPassword(props) {
  const id = localStorage.getItem("id");


    const [validated, setValidated] = useState(false);
    const [password,setPassword] = useState(false);
    const [conPassword,setConPassword] = useState(false);
    const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {

    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    setMessage("");

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    if (password.length < 6) {
      setMessage("لا يمكن ان تكون كلمة السر اصغر من 6 خانات")
      
      return;
        
    }
    if (password !== conPassword) {
      setMessage("كلمة السر غير متطابقة")
      
      return;
        
    }

      var login = await callApiWithGenerateToken(
        config.DOMAIN,
        { type: "resetPassword", lang: 1, new_pass: password, rewrite_pass: conPassword ,userId:id},
        "POST"
      );
      const data = login;
      setMessage(data.msg)


  };

  return (
    <div className="myaccc">

    
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="">
        <Col lg={5} className={"mt-2 mt-lg-3"}>
          <Form.Group className="mb-3">
            <p className="body-text-myAcc">كلمة السر الجديدة</p>
              <Form.Control
                required
                className=" checkout-form-input"
                name="password1"
                placeholder="كلمة السر الجديدة"
                type="password"

                onChange={(e) => setPassword(e.target.value)}
              />
          </Form.Group>
          <Form.Group className="mb-3">
            <p className="body-text-myAcc">تأكيد كلمة السر </p>
              <Form.Control
                required
                className=" checkout-form-input"
                name="password2"
                placeholder="تأكيد كلمة السر"
                type="password"

                onChange={(e) => setConPassword(e.target.value)}
              />
          </Form.Group>
            
        </Col>
      </Row>
      <Row className="new-password-row">
      <Button variant="light" type="submit" className="myAccount-button" >
              تغيير
       </Button>
       <div className="form-message"> {message}</div>
      </Row>
      
    </Form>
    </div>
  );
}
