
import React from 'react';
function Banner(props) {
const { img } = props
  return (
      <div className='position-relative'>
        <img src={img} className="img img-fluid banner-img" alt=""/>
        <div className='gradient-2'>
            </div> 
      </div>
  );
}

export default Banner;
