import React, { useEffect, useState, useCallback } from 'react';
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import Text from '../general/text';

function DiscountCode(props) {
    const {code, setCode, submitCouponCode, msg} = props;
    return (
        <Card>
            <CardBody className=''>
                <Text text="هل لديك كود خصم؟" />
                <Form onSubmit={(event) => submitCouponCode(event)} >
                    <Row className='justify-content-between'>
                        <Col xs={8}>
                            <FormGroup className='text-input'>
                                <Input
                                    id="code"
                                    name="code"
                                    placeholder="ادخل كود الخصم"
                                    type="text"
                                    size={1}
                                    value={code}
                                    onChange={(e) => setCode(`${e.target.value}`)}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={4}><button className='btn btn-white btn-white-w-auto w-100 px-2'>تطبيق</button></Col>
                        {msg != '' &&
                            <Col xs={12}><Text text={msg} /></Col>
                        }
                    </Row>
                </Form>
            </CardBody>
        </Card>


    );
}

export default DiscountCode;
