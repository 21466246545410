import { Contacts } from '@mui/icons-material';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Row, Col, Container } from 'reactstrap';
import page4img from "../../page4img.png"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'bootstrap/dist/css/bootstrap.css';
import Title from '../general/title';
import Text from '../general/text';

function aboutUs(props) {
  return (
    //   <div style={{ backgroundSize: 'cover', backgroundColor: '#282c34', flex: 1,  backgroundImage: props.data.description ? `url(${props.data.image})` : `url(${page4img})`}}>


    //   <Container>

    //   <Row md="12" xs='12'>
    //   <Col md='0' xs='1'></Col>
    //     <Col md='4' xs='10' className='paddingBottom-news-about'>
    //     <Row md='12' xs='12' className='alignItems'>

    //                   <Col md='1' xs='1'>
    //                   <Contacts className='icon-page' />
    //                   </Col>
    //                   <Col md='9' xs='10'>
    //                  <div className='title-page'>من نحن</div> 
    //                   </Col>
    //                 </Row>
    //     </Col>
    //   </Row>


    //   <Row md="12" xs='12'>
    //     <Col md='0' xs='1'></Col>
    //     <Col md='6' xs='10'>
    //     <p className='title-pargraph' dangerouslySetInnerHTML={{__html:props.data.moreFields['2_Text_Area_2_1']}} />
    //     <p className='pargraph-text' dangerouslySetInnerHTML={{__html:props.data.description}} />
    //     </Col>
    //   </Row>


    //   <Row md="12" xs='12'>
    //   <Col md='0' xs='1'></Col>
    //   <Col md='3' sm='3' xs='8'>
    //     <Link to='/AboutUs' exact={true}>
    //     <div className='button-page4-box'>
    //         <button className='button-page3'>
    //           عرض المزيد
    //         </button>
    //       </div>
    //   </Link>
    //     </Col>
    //   </Row>


    // </Container>
    // <div className='gradient'>
    //     </div>
    //  </div>





    <div className='index-bg-about' style={{ backgroundImage: props.data.image ? `url(${props.data.image})` : `url(${page4img})` }}>
        <Container>
          <Row className='mb-5 mb-lg-0'>
            <Col className='about-in-home' lg={9} xl={7} >
              <div className='d-flex align-items-center mb-5'>
                <Contacts className='icon-page icon-size' />
                <div className='title-page px-2'>من نحن</div>
              </div>
              <Text text={props.data.moreFields['2_Text_Area_2_1']} />
              <Text type={'tagHtml'} text={props.data.description} />
              <div className='my-4'>
              <Link to='/AboutUs' exact={true}>
                  <button className='btn btn-white'>
                    عرض المزيد
                  </button>
              </Link>
              </div>
            </Col>
          </Row>
        </Container>
      <div className='gradient'></div>
    </div>

  );
}

export default aboutUs;