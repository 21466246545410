import React, { useEffect, useState, useCallback,Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
import Text from '../general/text';
import OrderMenu from './orderMenu';


function CheckoutOrderSummary(props) {
    const cupon = localStorage.getItem("cuppon");
const type = localStorage.getItem("cType");
    const { payment, cart, tax, dataTemp, setDataTemp } = props

    return (
        <Card className='mb-4'>
            <CardBody className=''>
                <Text text="ملخص الطلب" />
                <Row className=''>
                    <Col xs={6}><Text text={'المجموع'} /></Col>
                    <Col xs={6} className='text-start fst-italic color-white'>${dataTemp.subTotal}</Col>
                    <Col xs={6}><Text text={'الضريبة %'+tax} /></Col>
                    <Col xs={6} className='text-start fst-italic color-white'>${dataTemp.totalWithTax}</Col>
                    {cupon &&
                    <Fragment>
                    <Col xs={6}><Text text={'الخصم '+ `${type == 0 ?'%'+cupon :"" }`} /></Col>
                    <Col xs={6} className='text-start fst-italic color-white'>${cart.discount}</Col>
                    </Fragment>
                    }
                    <Col xs={6} className={'mb-2'}><Text text={'المجموع الكلي'} /></Col>
                    <Col xs={6} className='text-start mb-2 fst-italic color-white'>${dataTemp.allTotal}</Col>
                    <Col xs={12} className="divider"></Col>
                </Row>
                {payment == 1 &&
                    <Row className='mt-3'>
                        <Col lg={12}>
                            <Text text="طريقة الدفع" />
                        </Col>
                        <Col lg={12}>
                            <Text text="الدفع عند الإستلام" />
                        </Col>
                        <Col xs={12} className="divider"></Col>
                    </Row>
                }
                <Row className='mt-3'>
                    <Col lg={12}>
                        <Text text="قائمة الطلبات" />
                    </Col>
                    <Col lg={12} className="h-order-menu">
                        {dataTemp?.itemInCart?.map((rowData, index) => {
                            return (
                                <OrderMenu
                                    image={rowData.image}
                                    qty={rowData.qty}
                                    name={rowData.name}
                                    price={rowData.price}
                                />
                            )
                        })}
                    </Col>
                </Row>

            </CardBody>
        </Card>


    );
}

export default CheckoutOrderSummary;
