import React, { useEffect, useState, useCallback } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { toAbsoluteUrl } from '../AssetHelpers'
import Banner from '../component/general/banner';
import BreadcrumbComp from '../component/general/breadcrumb';
import Text from '../component/general/text';
import Title from '../component/general/title';
import { callApiWithGenerateToken } from '../api/api'
import Cards from '../component/general/cards';
import { useParams } from 'react-router';
import Loading from '../component/Loading';

var config = require('../Config2.js')

function DetailsPage(props) {
  const { id, type } = useParams();
  const img = toAbsoluteUrl("/media/img/bg-about.png")
  const imgBanner = type == '3' ? toAbsoluteUrl("/media/img/imgNews.png") : toAbsoluteUrl("/media/img/bannerBlog.png")

  const [related, setRelated] = useState([]);
  const [bannerImg, setBannerImg] = useState([]);
  const [info, setInfo] = useState([])
  var page = type == '3' ? 'الأخبار' : 'المدونة'
  var link = type == '3' ? 'News' : 'Blog'
  const [breadcrumArr, setBreadcrumArr] = useState([
    {
      label: page,
      link: link
    },
  ])
  const logResult = useCallback(() => {
    return 2 + 2;
  }, []); //logResult is memoized now.

  useEffect(() => {
    getData()
  }, [logResult, id, type]);


  const getData = async () => {
    var details = await callApiWithGenerateToken(config.DOMAIN, {
      type: 'getPostById', id: id
    }, 'POST');

    if (details) {
      setBannerImg(details.data.moreFields)
      var breadcrum = [...breadcrumArr]
      breadcrum = breadcrum.slice(0,1)
     
      if (breadcrum.length == 1) {
        breadcrum.push({ label: details.data.name, link: '' })
        setBreadcrumArr(breadcrum)
      }
      setInfo(details.data)
      setRelated(details.related)
    }
  }

  return (
    <div className='bg-img' style={{ backgroundImage: `url(${img})` }}>
      {info != null && info != undefined && info != '' ?
        <>
          <div className='position-relative'>
            <Banner img={bannerImg?.['3_Image_3_2'] ? bannerImg['3_Image_3_2'] : imgBanner} />
            <BreadcrumbComp breadcrumData={breadcrumArr} />
            <div className='gradient-2'>
            </div>
          </div>
          <Container>
            {info?.image &&
              <Row>
                <Col md={12}>
                  <div className='w-100 text-center mt-5 img-content-container'>
                    <img src={info.image} className="img-fluid img-content" alt="" />
                  </div>
                </Col>
              </Row>
            }
            <Row className='mt-5 align-items-baseline'>
              <Col md={6} lg={8}>
                <Title title={info.name} />
                <Text text={info.description} type="tagHtml" />

              </Col>
              {related.length > 0 &&
                <Col md={6} lg={4}>
                  <Text text={type == '3' ? 'أخبار مشابهة':'مدونات مشابهة'} bold={'700'} />
                  {related?.map((rowData, index) => {
                    return (
                      <Cards id={rowData.id} type={rowData.categoryId} description={rowData.description} numberLetters={150} title={rowData['name']} image={rowData.image} />
                    )
                  })}
                </Col>
              }
            </Row>
          </Container>
        </>
        : <Loading />}


    </div>

  );
}

export default DetailsPage;
