// const checkToken = async () => {

//     var token = await localStorage('@EzyPay::token')

//     return token
// }

const callApiWithGenerateToken = async (domain, data, methodName = 'POST', files = false) => {
// console.log(123);
    // let expiresToken = await checkToken()
    // let expiresToken = '123DONTNEEDITNOW123'
 
     let resData = false;
    //  if (expiresToken != false && expiresToken != undefined && expiresToken != '' && expiresToken != null)
         resData = await callApi(domain, data, methodName, files);
     return resData;
 };


 const callApi = (domain, data, methodName = 'POST', files = false) => {
    var configApi = {};
    configApi['method'] = methodName;
    if (methodName != 'GET') {
        configApi['body'] = files ? data : JSON.stringify(data);
    } else {
        domain += '?';
        for (const key in data) {
            domain += key + '=' + data[key] + '&';
        }
    }
    if (methodName != 'GET') {
        configApi['headers'] = {
            'Accept': 'application/json',
        }
    } else { 
        configApi['headers'] = {
            'Accept': 'application/json',
        }
    }
    let resData = callAsyncAPI(domain, configApi);
    return resData;
};


const callAsyncAPI = async (domain, data, returnedValue = '') => {
    try {
        let response = await fetch(domain, data);
        let res = await response.json();

        if (res.code == 202) {
            alert(
               ['Error: Request Refused',
               'Token Expired Please Log In again']
            )
            return false;
        }
        else if (res.code == 404) {
            //not found
            alert(
                ['Error: Not Found 404',
                'Api Page Not Found Please Try Again Later']
            )
            return false;
        }
        
        else if (res.code == 401) {
            //authentication Error
            alert(
                ['Error: Authentication Failed',
                'Authentication Refused Please Contact Admin And Try Again Later']
            )
            return false;
        } else if (res.code == 500) {
            //internal server error
            alert(
               [ 'Error: Internal Server Error',
               'Internal Error Occurred Please Try Again Later']
            )
            return false;
        } else if (res.code == 200||res.code == 201 ) {
        // } else if (res.code >= 200 && res.status == true ) {
            //success
         
            return returnedValue ? res[`${returnedValue}`] : res;

        } else if (res.code == 405 || res.code == 400) {
            //405 => request refused 
            //400 => bad request
            alert(
               ['Error: Request Refused',
               'Your Request Has Been Refused From Server Please Try Again Later']
            )
            return false;
        }
        
        else {
            //internet error
            alert(
               ['Error: Internet Error',
                'Internet Connection Error, Please Check Your Connection And Try Again Later']
            )
            return false;
        }
    } catch (error) {
        //data error
        // alert(error)
        return false;
    }
}


export { callAsyncAPI, callApi, callApiWithGenerateToken };