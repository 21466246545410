import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
let Strings = [];
 
class MapForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            zoom: 16,
            maptype: 'roadmap',
            place_formatted: '',
            place_id: '',
            place_location: '',
        }
    }

    componentDidMount() {
       var locationData=[]
      locationData[0]=this.props.data[0]
      locationData[1]=this.props.data[1]
     
      

      let map = new window.google.maps.Map(document.getElementById('map-1' ), {
          center: { lat: parseFloat(locationData[0]), lng: parseFloat(locationData[1]) },
          zoom: 17,
          mapTypeId: 'roadmap',
          streetViewControl: false,
          draggable: true
      });

      map.addListener('zoom_changed', () => {
          this.setState({
              zoom: map.getZoom(),
          });
      });

      map.addListener('maptypeid_changed', () => {
          this.setState({
              maptype: map.getMapTypeId(),
          });
      });

      let marker = new window.google.maps.Marker({
          map: map,
          position: { lat: parseFloat(locationData[0]), lng: parseFloat(locationData[1]) },
          draggable: true
      });

  
      

      // initialize the autocomplete functionality using the #pac-input input box
   
  }

    render() {
        return (
            <React.Fragment>
                <div id='map-main-view' style={{position: 'relative'}}>
                    <div id={'map-1'} className='map-height' />
                    <div className='gradient-2'> </div> 
                </div>
            </React.Fragment>
        )
    }
}
export default MapForm;