import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Button,
} from "reactstrap";
import MyInfo from "../MyAccounts/MyInfo";
import NewPassword from "../MyAccounts/NewPassword";
import MyOrders from "../MyAccounts/MyOrders";
import classnames from "classnames";
import Cards from "./cards";
import Text from "./text";
import { Link } from "react-router-dom";

let tabs = [
  {
    id: 1,
    name: "المعلومات الشخصية",
  },
  {
    id: 2,
    name: "تغيير كلمة السر",
  },
  {
    id: 3,
    name:"طلباتي",
  },
];
export default class MyAccountVerticalTabs extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: tabs[0].id,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState(
        {
          activeTab: tab,
        }
      );
    }
  }

  render() {
    return (
      <Row className="justify-content-between  ">
        <Col md={3} lg={2}>
          <Nav tabs variant="pills" className="flex-column nav-style">
            {tabs.map((rowData, index) => {
              return (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === rowData.id,
                    })}
                    onClick={() => {
                      this.toggle(rowData.id);
                    }}
                  >
                    {rowData.name}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </Col>
        <Col md={9} lg={10}>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId={this.state.activeTab}>
              <Row>
                { this.state.activeTab === 1 && <MyInfo />   }
                { this.state.activeTab === 2 && <NewPassword />   }
                { this.state.activeTab === 3 && <MyOrders />   }
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    );
  }
}
