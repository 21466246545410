import React from "react";
import { Col, Row } from "reactstrap";
import BannerSlider from "../component/index/bannerSlider";
import SideBar from "../component/sidebar/sidebar";
import { ProSidebarProvider } from 'react-pro-sidebar';
import SliderTournaments from "../component/MasterPage/SliderTournaments";
import { toAbsoluteUrl } from '../AssetHelpers'
import Title from "../component/general/title";
import GamesBoxes from "../component/MasterPage/GamesBoxes";

function Index() {

    const tournaments_title = toAbsoluteUrl("/media/img/tournaments-title.svg")
    const Icongame = toAbsoluteUrl("media/img/Icongame.svg")

    return (
        <ProSidebarProvider>
            <div className="d-flex">
                <SideBar />
                <div className="mx-4 w-100 overflow-hidden mt-5">
                    <Row>
                        <Col xs={12}>
                            <BannerSlider />
                        </Col>
                        <Col xs={12}>
                            <div className="title-genral">
                                <img src={tournaments_title} />
                                <div className="mx-4">
                                <Title title="البطولات المميزة"/>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <SliderTournaments type="tournaments"/>
                        </Col>
                        <Col xs={12}>
                            <div className="title-genral">
                                <img src={Icongame} />
                                <div className="mx-4">
                                <Title title="الألعاب الاكثر شهرة"/>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <SliderTournaments type="games"/>

                            {/* <GamesBoxes/> */}
                        </Col>
                    </Row>
                </div>
            </div>
        </ProSidebarProvider>

    )
}

export default Index;