import React, { useState } from "react";
//react pro sidebar components

import {
    Menu,
    MenuItem,
    useProSidebar,
    Sidebar,

} from "react-pro-sidebar";
//icons from react icons
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { toAbsoluteUrl } from '../../AssetHelpers'
import HomIcon from "../icon/home";

const SideBar = () => {
    const img_not_found = toAbsoluteUrl("/media/img/not_found.png")
    const img_sidebar = toAbsoluteUrl("/media/img/img-sidebar.png")
    // const home = toAbsoluteUrl("/media/img/home.svg")
    const noun_game = toAbsoluteUrl("/media/img/noun-game.svg")
    const fire_1 = toAbsoluteUrl("/media/img/fire_1.svg")
    const fire_2 = toAbsoluteUrl("/media/img/fire_2.svg")
    const fire_3 = toAbsoluteUrl("/media/img/fire_3.svg")
    const store = toAbsoluteUrl("/media/img/store.svg")
    const person = toAbsoluteUrl("/media/img/person.svg")
    const noun_sign_out = toAbsoluteUrl("/media/img/noun_sign_out.svg")

    const { collapseSidebar } = useProSidebar();

    return (
        <div>
            <div className="sidebar-style">
                <Sidebar>
                    <Menu>
                        <MenuItem></MenuItem>
                        <MenuItem active={true} icon={<HomIcon />}>
                            <Link to="/index">الرئيسية</Link>
                        </MenuItem>
                        <MenuItem icon={<img src={noun_game}/>} >
                            <Link to="/Games" >الألعاب</Link>
                            <Badge className="btn-gradinet" pill>8</Badge>
                        </MenuItem>
                        {/* <MenuItem icon={<MatchIcon />}><Link to="/">مبارياتي</Link></MenuItem> */}
                        <MenuItem icon={<img src={fire_1}/>}> <Link to="/">فريقي </Link></MenuItem>
                        <MenuItem icon={<img src={fire_2}/>}><Link to="/">بطولاتي </Link></MenuItem>
                        <MenuItem icon={<img src={fire_3}/>}><Link to="/">ساحات القتال</Link></MenuItem>
                        <MenuItem icon={<img src={store}/>}>
                            <Link to="/">المتجر </Link>
                            <Badge className="btn-gradinet" pill>+99</Badge>

                        </MenuItem>
                        <div className="divider"></div>
                        <MenuItem icon={<img src={person}/>}><Link to="/">أصدقائي </Link></MenuItem>
                        <MenuItem icon={<img src={noun_sign_out}/>}><Link to="/">تسجيل الخروج </Link></MenuItem>
                        <div className="img-in-sidebar">
                            <img src={img_sidebar} className="img-fluid" alt="..." />
                            <div className="img-in-sidebar-content">
                                <p>هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديله</p>
                                <button className="btn btn-white">تحميل</button>
                            </div>
                        </div>
                    </Menu>
                </Sidebar>
                <main className="collapse-sidebar mt-collapse-sidebar">
                    <button className="btn-gradinet btn-collapse-sidebar" onClick={() => collapseSidebar()}><FaAngleRight /></button>
                </main>
            </div>
            
        </div>
    );
}
export default SideBar