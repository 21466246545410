import React, { useState } from "react";
import Text from "../general/text";
import { toAbsoluteUrl } from '../../AssetHelpers'
import { Col, Row } from "reactstrap";
import Title from "../general/title";

const TournamentsCard = (props) => {
    const index_banner = toAbsoluteUrl("/media/img/card-1@2x.png")
    const fire_tourn = toAbsoluteUrl("/media/img/fire-tourn.svg")
    const noun_award = toAbsoluteUrl("/media/img/noun-award.svg")
    const noun_team = toAbsoluteUrl("/media/img/noun-team.svg")
    const gift_1 = toAbsoluteUrl("/media/img/gift-1.svg")
    const check_blue = toAbsoluteUrl("/media/img/check-blue.svg")
    const noun_headphone = toAbsoluteUrl("/media/img/noun-headphone.svg")
    const calendar_alt = toAbsoluteUrl("/media/img/calendar-alt.svg")
    const {cardActive , handleCardActive, id = 1} = props;

    return (
        <Row className="px-2">
            <Col xs={12} onClick={handleCardActive}>
                <div className={cardActive == id ? 'bg-image bg-gradient-color tournaments-card p-2 p-xxl-3 tournaments-card-active' : "bg-image bg-gradient-color tournaments-card p-2 p-xxl-3"} style={{ backgroundImage: `url(${index_banner})` }}>
                    <div className="tournaments-card-content mb-4">
                        <div className="tournaments-card-content-flex">
                            <img src={calendar_alt} className="img-fluid img-calender" alt="..." />
                            <div className="mx-2">
                                <p>Thu , 14 April , 9:00 PM</p>
                                <p>Amman , Jordan</p>
                            </div>
                        </div>
                        <div className="bg-white tournaments-card-content-flex">
                            <p>1,580</p>
                            <img src={noun_team} className="img-fluid" alt="..." />
                        </div>
                        <div className="position-relative top-img">
                            <img src={noun_award} className="img-fluid" alt="..." />
                            <img src={gift_1} className="img-fluid" alt="..." />
                        </div>
                    </div>
                    {cardActive == id && <Title title="انضم إلينا" />}
                    <Text text="نالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية " />
                    <div className="tournaments-card-content">
                        <div className="tournaments-card-content-flex">
                            <img src={fire_tourn} className="img-fluid" alt="..." />
                            <p>FIFA Mobile MENA</p>
                            <img src={check_blue} className="img-fluid" alt="..." />
                        </div>
                        <div className="tournaments-card-content-flex">
                            <img src={noun_headphone} className="img-fluid mx-2 img-headphone" alt="..." />
                            <p>Online</p>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>

    )

}

export default TournamentsCard
