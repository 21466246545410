import React, { useEffect, useState, useCallback } from "react";
import { Col, Row, FormGroup, FormFeedback } from "reactstrap";
import Form from "react-bootstrap/Form";
import Text from "../general/text";
import { callApiWithGenerateToken, callApi, callAsyncAPI } from "../../api/api";
import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import ClearIcon from '@mui/icons-material/Clear';
var config = require("../../Config2.js");
function CheckoutForm(props) {
  const { orderId, dataTemp, setDataTemp, cart, setCart, getAllCartData } =
    props;
  const [validated, setValidated] = useState(false);
  const [phone, setPhone] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [message, setMessage] = useState("");
  const logged = localStorage.getItem("token");

  const logResult = useCallback(() => {
    return 2 + 2;
  }, []); //logResult is memoized now.

  useEffect(() => {
    // getData()
    if (logged) {
      setFname(localStorage.getItem("fname"));
      setLname(localStorage.getItem("lname"));
      setPhone(localStorage.getItem("phone"));
      setEmail(localStorage.getItem("email"));
      setCountry(localStorage.getItem("country"));
      setAddress(localStorage.getItem("address"));
      setPostalCode(localStorage.getItem("zipcode"));
    }
  }, [logResult, orderId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    var dataApi = {
      type: "confirmOrder",
      lang: 1,
      orderId: orderId,
      token: logged,
      shippingInfo: {
        postalCode: postalCode,
        address: address,
        country: country,
        fname: fname,
        lname: lname,
        phone: phone,
      },
    };
    if (logged) {
      dataApi.shippingInfo.userId = "";
    } else {
      dataApi.shippingInfo.email = email;
    }
    var response = await callApiWithGenerateToken(
      config.DOMAIN,
      dataApi,
      "POST"
    );
    if (response.code == 200) {
      props.setPayment(1);
      localStorage.removeItem("cart");
      localStorage.removeItem("orderId")
      localStorage.removeItem("cuppon");
      localStorage.removeItem("cType");
      localStorage.setItem("fname", fname);
      localStorage.setItem("lname", lname);
      localStorage.setItem("phone", phone);
      localStorage.setItem("country",country);
      localStorage.setItem("address",address);
      localStorage.setItem("zipcode", postalCode);
      getAllCartData([]);
    }
    else if( response.validation == 1){
      setMessage(response.msg);
      return;
    }
    else if (response.code == 201) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui alert-box-view'>
              <div className="mb-4">
                <span className="alert-Succsess-icon failed"><ClearIcon /></span>
              </div>
              <h5>هذا المنتج غير متوفر بالكمية المطلوبة</h5>
              <br></br>
              <button type="button" className="btn btn-cancel mx-2" onClick={onClose}>إغلاق</button>
            </div>
          );
        }
      });
   
      return;
    }
  
    // if (response.msg == 'Added') {
    //     // setPhone('')
    //     // setFname('')
    //     // setEmail('')
    //     // setLname('')
    //     // setMessage('')
    //     // setShowMessage(true)
    // }
    // // setIsOpen(false)
  };

  return (
    <div>
      {props.payment == "0" ? (
        <Form noValidate className="login-form" validated={validated} onSubmit={handleSubmit}>
          <Row className="">
            <Col lg={5} className={"mt-2 mt-lg-3"}>
              <Text text="المعلومات الشخصية" />
              <Form.Group className="mb-3">
                <Form.Control
                  required
                  className=" checkout-form-input"
                  name="fistName"
                  placeholder="الاسم الاول"
                  type="text"
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                />

              </Form.Group>
              <FormGroup className="mb-3">
                <Form.Control
                  required
                  name="lname"
                  placeholder="الاسم الأخير"
                  type="text"
                  className=" checkout-form-input"
                  value={lname}
                  onChange={(e) => setLname(`${e.target.value}`)}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Form.Control
                  required
                  id="phone"
                  name="phone"
                  placeholder="رقم الهاتف"
                  type="number"
                  className=" checkout-form-input"
                  value={phone}
                  onChange={(e) => setPhone(`${e.target.value}`)}
                />
              </FormGroup>
              {!logged ? (
                <FormGroup className="mb-3">
                  <Form.Control
                    required
                    id="lname"
                    name="lname"
                    placeholder="البريد الالكتروني"
                    type="email"
                    className=" checkout-form-input"
                    value={email}
                    onChange={(e) => setEmail(`${e.target.value}`)}
                  />
                  <Form.Control.Feedback className="email-validation-message" type="invalid">
                    ادخل بريد الكتروني صالح          </Form.Control.Feedback>
                </FormGroup>
              ) : (
                ""
              )}
            </Col>
            <Col lg={1} className="d-none d-lg-block">
              <div className="d-flex divider-vertical">
                <div className="vr"></div>
              </div>
            </Col>
            <Col lg={5} className={"mt-2 mt-lg-3"}>

              <Text text="معلومات الشحن" />
              <FormGroup >
                <Form.Control
                  required
                  id="country"
                  name="country"
                  placeholder="المدينة"
                  type="text"
                  className=" checkout-form-input"
                  value={country}
                  onChange={(e) => setCountry(`${e.target.value}`)}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Form.Control
                  required

                  name="address"
                  placeholder="العنوان"
                  type="text"
                  className="checkout-form-input"
                  value={address}
                  onChange={(e) => setAddress(`${e.target.value}`)}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Form.Control
                  required
                  name="postalCode"
                  placeholder="الرمز البريدي"
                  type="number"
                  className="checkout-form-input"
                  value={postalCode}
                  onChange={(e) => setPostalCode(`${e.target.value}`)}
                />
              </FormGroup>
            </Col>
            <div className="form-message"> {message}</div>
          </Row>
          <Row className="my-4 align-items-center">
            <Col xs={6} lg={5}>
              <Link className="color-white" to="/Cart">
                رجوع
              </Link>
            </Col>
            <Col xs={6} className="text-start">
              <button type="submit" className="btn btn-white btn-white-w-auto">
                دفع الآن
              </button>
            </Col>
            
          </Row>
        </Form>
      ) : (
        <Row>
          <Col className="text-center">
            <Text text="شكرا لك" />
            <Row>
              <Text text={"رقم طلبك هو #" + orderId} />
            </Row>

            <Text text="سوف تصلك رسالة تحقق على بريدك " />
            <Text text={email} />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default CheckoutForm;
