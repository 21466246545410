import { BrowserRouter as Router, Route, Link, Routes, Navigate } from "react-router-dom";
import backGround from "./backGH.png"

import Header from "./component/Header"
import Footer from "./component/Footer"
import Home from "./pages/Home"
import Product from "./pages/Products"
import Blog from "./pages/Blog"
import News from "./pages/News"
import AboutUs from "./pages/AboutUs"
import ContactUs from "./pages/ContactUs"
import Games from "./pages/Games"
import "./App.css"
import ProductInfo from "./pages/productInfo";
import DetailsPage from "./pages/content";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import { useEffect, useState } from "react";
import { callApiWithGenerateToken } from './api/api'
import Index from "./pages/index";
import MyAccount from "./pages/MyAccount";

var config = require('./Config2.js')


function App() {
  let token = localStorage.getItem("token");
  const cartLocalStorage = JSON.parse(localStorage.getItem('cart'))
  const [cart, setCart] = useState(cartLocalStorage ? cartLocalStorage : { 'itemInCart': [], subTotal: 0, totalWithTax: 0, allTotal: 0 })
  const [count, setCount] = useState(1);
  const [code, setCode] = useState('');
  const [tax, setTax] = useState(0);
  const [msg, setMsg] = useState('');
  const [orderId, setOrderId] = useState(JSON.parse(localStorage.getItem('orderId')) || '')
  const [dataTemp, setDataTemp] = useState(JSON.parse(localStorage.getItem('dataTemp')) || [])
  const [login, setLogin] = useState(true);
  const [codeType, setCodeType] = useState(0);

  // const [code, setCode] = useState('');


  useEffect(() => {
    getTax()
  }, [cart, orderId, code])

  const getTax = async () => {
    var taxValue = await callApiWithGenerateToken(config.DOMAIN, { type: 'getTax' }, 'POST');
    if (taxValue) {
      setTax(taxValue.msg)
    }
  }
  const submitCouponCode = async (event) => {
    event.preventDefault();
    var dataApi = {
      "type": "isCouponValid",
      "code": code,
      "token": window.TOKEN
    }
    var codeSubmit = await callApiWithGenerateToken(config.DOMAIN, dataApi, 'POST');
    if (codeSubmit.code !== 200) {
      setMsg('هذا الكوبون غير صالح')

    } else {
      setCode(codeSubmit.info.code);
      localStorage.setItem("cuppon", codeSubmit.info.amount);
      localStorage.setItem("cType", codeSubmit.info.type);
      setMsg('تم تفعيل كود الخصم بنجاح');
      getAllCartData(cart.itemInCart)
    }

  }

  const getAllCartData = (cartItem) => {
    var subTotal = 0;
    var totalWithTax = 0;
    var beforePrice = 0;
    var totalWithCuppon = 0;
    var allTotal = 0;
    var data = []
    const cupon = localStorage.getItem("cuppon");
    if (cartItem != undefined && cartItem != null && cartItem != '') {
      subTotal = cartItem.reduce((sum, { price, qty }) => sum + parseFloat(price) * parseInt(qty), 0);
      beforePrice = subTotal;
      if (tax) {
        totalWithTax = tax / 100
        totalWithTax = subTotal * totalWithTax;
        subTotal = subTotal + totalWithTax;

      }
      if (cupon) {
        const type = localStorage.getItem("cType");
        if (type == 0) {
          totalWithCuppon = cupon / 100;
          
          totalWithCuppon = subTotal * totalWithCuppon;
          subTotal = subTotal - totalWithCuppon;
        }
        else {
          totalWithCuppon = cupon;
        }

      }
      allTotal = subTotal ;
      data = { 'itemInCart': [...cartItem], 'subTotal': beforePrice, 'totalWithTax': totalWithTax.toFixed(2), 'allTotal': allTotal.toFixed(2) }

      if (cupon) {
        data.discount = totalWithCuppon;
      }


      setCart(data)
      setDataTemp(data)
      localStorage.setItem('cart', JSON.stringify(data));
      localStorage.setItem('dataTemp', JSON.stringify(data));

    }
    else {
      data = { 'itemInCart': [...cartItem], 'subTotal': subTotal, 'totalWithTax': totalWithTax.toFixed(2), 'allTotal': allTotal.toFixed(2) }
      setCart(data)
      localStorage.setItem('cart', JSON.stringify(data));
      localStorage.setItem('dataTemp', JSON.stringify(data));
    }
  }
  return (
    <div className="App" >

      <Router>
        <Header cart={cart} setCart={setCart} />
        <div className="main-page-height">
          <Routes>
            <Route exact path="*" element={<Navigate to='/' />} />
            <Route exact path="/" element={<Home />} />
            <Route exact path="/Home" element={<Home />} />
            <Route exact path="Product" element={<Product />} />
            <Route exact path="productInfo/:id" element={<ProductInfo cart={cart} setCart={setCart} count={count} setCount={setCount} getAllCartData={getAllCartData} />} />
            <Route exact path="Blog" element={<Blog />} />
            <Route exact path="News" element={<News />} />
            <Route exact path="content/:id/:type" element={<DetailsPage />} />
            <Route exact path="AboutUs" element={<AboutUs />} />
            <Route exact path="ContactUs" element={<ContactUs />} />
            <Route exact path="Games" element={<Games />} />
            <Route exact path="Cart" element={<Cart cart={cart} setCart={setCart} count={count} setCount={setCount} getAllCartData={getAllCartData} tax={tax} orderId={orderId} setOrderId={setOrderId} code={code} setCode={setCode} submitCouponCode={submitCouponCode} msg={msg} />} />
            <Route exact path="Checkout" element={<Checkout cart={cart} setCart={setCart} tax={tax} orderId={orderId} setOrderId={setOrderId} getAllCartData={getAllCartData} dataTemp={dataTemp} setDataTemp={setDataTemp} />} />
            {token && <Route exact path="MyAccount" element={<MyAccount />} />}
            {login &&

              <Route exact path="/index" element={<Index />} />

            }



          </Routes>
        </div>
        <Footer />




      </Router>
    </div>
  );
}

export default App;