
import React from 'react';
import { Row, Col } from 'reactstrap';

function Title(props) {
    const { title } = props
    return (
        <Row>
            <Col md={12}>
                <div className='title-text'>{title}</div>
            </Col>
        </Row>
    );
}

export default Title; 
