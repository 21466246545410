import { Row, Col, Container, Card, CardBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import backGround from "../../backGH.png"
import Text from '../general/text';
import Title from '../general/title';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function welcomeText(props) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  const dat = props.data;
  return (
    <Col md={12} sm={12}>
      <Carousel
        autoPlay={true}
        autoPlaySpeed={3000}
        swipeable={false}
        draggable={false}
        showDots={true}
        infinite={true}
        responsive={responsive}
        arrows={false}
        draggable={true}
      >
      {dat.map((item, index) => {
        return (
          

            <div className='Home-Top' style={{ backgroundImage: `url(${item.image})` }}>
              <div className='Box-Text'>
                <Container className='Home-welcome-section' >
                  <Row>
                    <Col md={12}>
                      <Title title={item.name} />
                      <Text type={'tagHtml'} text={item.description} />
                    </Col>
                  </Row>
                </Container>
              </div>

              <div className='gradient'>
              </div>
            </div>
        
            )
        })}
          </Carousel>
    </Col>


  );
}

export default welcomeText;




