import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { callApiWithGenerateToken } from "../../api/api";

var config = require("../../Config2.js");
function ResetPassword(state) {
  const [newPassword, setNewPassword] = useState("");
  const [conNewPassword, setConfirmNewPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState("");
  const email = localStorage.getItem("forgetEmail");
  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setMessage("");
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    } else if (newPassword !== conNewPassword) {
      event.preventDefault();
      event.stopPropagation();
      setMessage("Passwords are not match");
      return;
    }
    var reset = await callApiWithGenerateToken(
      config.DOMAIN,
      {
        type: "updateUserPassword",
        lang: 1,
        email : email,
        new_pass: newPassword,
        rewrite_pass: conNewPassword,
      },
      "POST"
    );
    
    if (reset.code === 200) {
      state.changeShowPage(6);
    }
    else {
      setMessage(reset.msg);
    }
  }
  return (
    <>
      <div className="">
        <Form
          className="reset-password-form"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <p>إعادة ضبط كلمة السر</p>
          <Form.Group className="reset-form-Group">
            <Form.Group className="mb-3 ">
              <Form.Label className="  ">كلمة السر الجديدة</Form.Label>
              <Form.Control
                required
                type="password"
                name="newPassword"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3 ">
              <Form.Label>إعادة كتابة كلمة السر الجديدة</Form.Label>
              <Form.Control
                required
                type="password"
                name="confirmNewPassword"
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </Form.Group>
            <div className="form-validation-message"> {message}</div>
          </Form.Group>
          
          <Form.Group className="  div-button-reset">
            <Button type="submit" className="gradient-button-view gradient-button">
              حفظ
            </Button>
            <Button variant="" onClick={state.close}>الغاء</Button>
          </Form.Group>
        </Form>
      </div>
    </>
  );
}

export default ResetPassword;
