import React, { useCallback, useState, useEffect } from 'react';
import '../App.css';

import productBg from "../productBg.png";
import WelcomeText from '../component/home/welcomeText'
import Loading from '../component/Loading'
import Products from '../component/home/products'
import Blog from '../component/home/blog'
import News from '../component/home/news'
import AboutUs from '../component/home/aboutUs'
import VideoComponent from '../component/home/video'
import ContactUs from '../component/home/contactUs'
import JoinNews from '../component/home/joinNews'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.css';

import {callApiWithGenerateToken} from '../api/api'

var config = require('../Config2.js')   

function Home() {

  const [productsData, setProducts] = useState('');
  const [welcomeData, setWelcomeData] = useState('');
  const [aboutBlogNews, setAboutBlogNews] = useState('');
  const [contactUsVideo, setContactUsVideo] = useState('');
  
  const logResult = useCallback(() => {
    return 2 + 2;
  }, []); //logResult is memoized now.

  useEffect(() => {
    getData()
    },[logResult]);

    const getData = async()=>{
      var welcTextAndProductData = await callApiWithGenerateToken(config.DOMAIN, {type: 'getHomePageData1', lang:1}, 'POST');
      var aboutNewsBlog = await callApiWithGenerateToken(config.DOMAIN, {type: 'getHomePageData2', lang:1}, 'POST');
      var videoContactUs = await callApiWithGenerateToken(config.DOMAIN, {type: 'getHomePageData3', lang:1}, 'POST');
      
        
        if(welcTextAndProductData && aboutNewsBlog && videoContactUs){
          setProducts(welcTextAndProductData.data.products)
          setWelcomeData(welcTextAndProductData.data.slider)
          setAboutBlogNews(aboutNewsBlog)
          setContactUsVideo(videoContactUs)
        }
      }
 
  return (
    <div className='home' style={{ backgroundColor: '#1E1E34' }}>
      {welcomeData ? <WelcomeText data={welcomeData}/>: <Loading/>}
      <div className='product' style={{ backgroundSize: 'cover', alignItems: 'center', paddingTop: 50, justifyContent: 'center', backgroundImage: `url(${productBg})` }}>
      <div className='product-box' >
        { productsData ? <Products data={productsData}/> : null}          
        {aboutBlogNews ? <Blog data={aboutBlogNews.data.blog}/> : null}
      </div>
      </div>
      {aboutBlogNews ? <News data={aboutBlogNews.data.news[0]}/> : null}
      {aboutBlogNews ? <AboutUs data={aboutBlogNews.data.aboutUs}/> : null}
      {contactUsVideo ? <VideoComponent data={contactUsVideo.data.video.description} image={contactUsVideo.data.video.image} /> : null}
      {contactUsVideo ? <ContactUs data={contactUsVideo.data.contactUs}/> : null}
      <JoinNews/>
    </div>
  );
}

export default Home;
 