import React, { useState } from "react";
import Text from "../general/text";
import { toAbsoluteUrl } from '../../AssetHelpers'
import { Col, Row } from "reactstrap";
import Title from "../general/title";
import Slider from "react-slick";

const Banner = () => {
    const index_banner = toAbsoluteUrl("/media/img/slide.svg")
 
    return (

        <div className="bg-banner-Games" style={{ backgroundImage: `url(${index_banner})` }}>
            <Row className="align-items-center h-100 header-font-Games">
                <Col lg={6} xl={4} className="mx-5">
                    <Title title="انضم إلينا" />
                    <Text text="نالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية " />
                    <button className="btn btn-gradinet w-50" >البدء</button>
                </Col>
            </Row>
        </div>
    )

}

export default Banner
