
import React, { useEffect, useState } from 'react';
import { CardBody,Card, Button } from 'reactstrap';
import Text from './text';
import { toAbsoluteUrl } from '../../AssetHelpers'
import { Link } from 'react-router-dom';

function Cards(props) {
    const { image , title, description, numberLetters, id, type } = props
    var text = props?.description.substring(0, numberLetters)
    const img_not_found = toAbsoluteUrl("/media/img/not_found.png")

    return (
        <Card className='mergin-bottom'>
        <CardBody>
            <div className='latest-news-h-img'><img src={image != null && image != undefined && image != '' ? image : img_not_found} className='img-fluid h-100 w-100' /></div>
            <div className='mt-4 min-h-content'>
                <h5 className='color-white line-no'>{title}</h5>
                <Text text={props?.numberLetters ? text : description} line={'line-no'} img={'newsImag'} type="tagHtml"/>
                <Link className='btn btn-outline-white' to={'/content/'+id+'/'+type}>عرض المزيد</Link>
            </div>
        </CardBody>
    </Card>
    );
}

export default Cards;