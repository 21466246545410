import React, { useEffect, useState, useCallback } from 'react';
import { Col, Container, Form, Input, Row, FormGroup } from 'reactstrap';
import { toAbsoluteUrl } from '../AssetHelpers'
import Banner from '../component/general/banner';
import BreadcrumbComp from '../component/general/breadcrumb';
import Text from '../component/general/text';
import Title from '../component/general/title';
import { callApiWithGenerateToken } from '../api/api'
import { Link } from 'react-router-dom';
import CheckoutForm from '../component/checkout/checkoutForm';
import CheckoutOrderSummary from '../component/checkout/checkoutOrderSummary';


var config = require('../Config2.js')
function Checkout(props) {

    const { cart, setCart, tax, orderId, setOrderId, dataTemp, getAllCartData,setDataTemp} =props;
    const img = toAbsoluteUrl("/media/img/bg-about.png")
    const imgBanner = toAbsoluteUrl("/media/img/bannerNews.png")

    const [payment, setPayment] = useState(0);
    const [breadcrumArr, setBreadcrumArr] = useState([
        {
            label: 'سلة التسوق',
            link: ''
        },
    ])



    return (

        <div className='bg-img cart-page checkout' style={{ backgroundImage: `url(${img})` }}>
            {/* {info != null && info != undefined && info != '' ? */}
            <>
                <div className='position-relative'>
                    <Banner img={imgBanner} />
                    <BreadcrumbComp breadcrumData={breadcrumArr} />
                    <div className='gradient-2'>
                    </div>
                </div>
                <Container>
                    <Title title={'سلة التسوق'} />
                    <Row className={payment == '0' ? 'my-4' : 'my-4 align-items-center'}>
                        <Col md={6} lg={8}>
                            <CheckoutForm payment={payment} setPayment={setPayment} orderId={orderId} setOrderId={setOrderId} cart={cart} setCart={setCart} dataTemp={dataTemp} setDataTemp={setDataTemp} getAllCartData={getAllCartData} />
                        </Col>
                        <Col md={6} lg={4} className="my-4 mt-lg-0 pe-xl-4">
                            <CheckoutOrderSummary payment={payment} cart={cart} setCart={setCart} tax={tax} dataTemp={dataTemp} setDataTemp={setDataTemp} />
                        </Col>
                    </Row>
                </Container>
            </>
            {/* : <Loading />} */}


        </div>

    );
}

export default Checkout;
