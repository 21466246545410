import React, { useState } from 'react';
import { Row, Col, Container, Input } from 'reactstrap';

import { callApiWithGenerateToken } from '../../api/api'


function ContactUs(props) {

  return (
    <div className='join-news'>
      <Container>
        <Row className='justify-content-center'>
        <Col md={8} xl={5}>
           <p className='join-news-labil'>اشترك في النشرة الإخبارية لدينا للحصول على آخر التحديثات</p>

           </Col>
        </Row>
        <form action="https://toreed.us11.list-manage.com/subscribe/post" method="POST">
          <Row className='justifyContent' style={{ borderRadius: 9 }}>
            <Col md={8} xl={5} className='position-relative'  >

              <input type="hidden" name="u" value="06ac6e560b9919baec111398e" />
              <input type="hidden" name="id" value="cf34f92faa" />

              <Input
                className='border-radius-input-join'
                placeholder="ادخل بريدك الالكتروني"
                type="email"
                name="MERGE0"
                id="MERGE0"
              />
              <div className=' btn-absolute'>
                <button type='submit' name="submit" className='btn button-join'>
                  اشتراك
                </button>
              </div>
            </Col>
           

          </Row>
        </form>
      </Container>
    </div>

  );
}

export default ContactUs;
