

import React from 'react';
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
import classnames from 'classnames';
import Cards from './cards';
import Text from './text';
import { Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import ProductCard from '../product/productCard';

export default class VerticalTabsWithSubTabs extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 20,
            dropdownOpen: false,
            activeDropdown: 20

        };
    }
    toggleDropdown = (dropdown) => {
        if (this.state.activeDropdown !== dropdown) {
            this.setState({
                dropdownOpen: !this.state.dropdownOpen,
                activeDropdown: dropdown
            })
        }
        else {this.setState({
            dropdownOpen: " ",
            activeDropdown: " "
        })
        }
    }
    componentDidMount() {

    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            }, function () {
                this.props.getData(tab, 1)
            });
        }
    }

    render() {
        return (
            <Row className='justify-content-between'>
                <Col md={3} xl={2} className="mb-5">
                    <Nav tabs variant="pills" className="flex-column nav-style tabs-style">
                        {this.props.tab.map((rowData, index) => {
                            if (((rowData.child != null && rowData.child != undefined && rowData.child != '') || rowData.child.length != 0 ) || rowData.postType != 1 ) {
                                return (
                                    <>
                                        <Row onClick={() => this.toggleDropdown(rowData.id)} className={classnames({ active: this.state.activeDropdown === rowData.id }) + ' tabs-act'}>
                                            <Col xs={10} md={8} lg={9} xl={10} className={'tabs-title'} title={rowData.name}>{rowData.name}</Col>
                                            <Col xs={2} md={4} lg={3} xl={2} >
                                                <div className={this.state.activeDropdown == rowData.id ? 'category-expand' : 'category-expand'} >
                                                    {this.state.activeDropdown == rowData.id ? <ExpandLess className='category-icone' /> : <ExpandMore className='category-icone' />}
                                                </div>
                                            </Col>
                                        </Row>
                                        {this.state.activeDropdown == rowData.id &&
                                            rowData.child.map((child, indexSub) => {
                                              
                                                return ( 

                                                     (child.postType != 1 ? <NavItem className='sub-item'>
                                                        <NavLink className={classnames({ active: this.state.activeTab === child.id })}
                                                            onClick={() => { this.toggle(child.id); }}
                                                            title={child.name}
                                                        >
                                                            {child.name.substring(0, 12) + "..."}</NavLink>
                                                    </NavItem> : ""
                                                ))
                                            })

                                        }
                                    </>
                                )
                            } 
                            else{
                                return( 
                                <Row onClick={() => { this.toggle(rowData.id); }}  className={classnames({ active: this.state.activeDropdown === rowData.id }) + ' tabs-act'}>
                                            <Col xs={10} md={8} lg={9} xl={10} className={'tabs-title'}>{rowData.name}</Col>
                                            <Col xs={2} md={4} lg={3} xl={2} >
                                               
                                            </Col>
                                </Row>)
                            }

                        })}

                    </Nav>
                </Col>
                <Col md={9} xl={10} className={'px-4'}>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId={this.state.activeTab}>
                            <Row>
                                {this.props.items != null && this.props.items != undefined && this.props.items != '' ?
                                    this.props.items.map((rowData, index) => {
                                        
                                            return (
                                                <Col xs={12} lg={6} className="mb-2 px-3">
                                                    <ProductCard image={rowData?.image}
                                                        fullContent={'1'}
                                                        title={rowData?.name}
                                                        description={rowData?.description}
                                                        numberLetters={120} id={rowData?.id} 
                                                        price={rowData['moreFields']['12_Text_12_5']}
                                                        />
                                                </Col>
                                            )
                                      
                                    })
                                    :
                                    <Col md={12} className="my-4 text-center no-data">
                                        <Text text="لا يوجد بيانات لعرضها حاليا" />
                                    </Col>

                                }

                                {this.props.loading == 1 && this.props.flagButton != this.props.page && this.props.items.length > 0 &&
                                    <Col md={12} className="text-center my-5">
                                        {this.props.countPage > 1 ?
                                            <Stack className='pagination-box' spacing={2}>
                                                <Pagination className='pagination-box' count={this.props.countPage} page={this.props.page} onChange={this.props.changePage} />
                                            </Stack> : null}

                                    </Col>
                                }

                            </Row>
                        </TabPane>

                    </TabContent>
                </Col>
            </Row>
        );
    }
}
