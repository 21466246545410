
import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import Slider from "react-slick";
import { toAbsoluteUrl } from '../../AssetHelpers'


function ProductSlider(props) {
    const img_not_found = toAbsoluteUrl("/media/img/not_found.png")

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 0,
              
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 0,

              }
            }
          ]
    };
    const { imgSlider } = props
    return (
        <Row>
            <Col md={12}>
                {imgSlider != null && imgSlider != undefined && imgSlider != '' &&
                    <Card className='slider-width slider-style'>
                        <CardBody>
                            <Slider {...settings}>
                                {imgSlider.map((rowData, index) => {
                                    return (
                                        <div key={index} className="text-center">
                                            <img src={rowData ? rowData : img_not_found} className="img-fluid" alt="" />
                                        </div>
                                    )
                                })}
                                
                            </Slider>
                        </CardBody>
                    </Card>
                }
            </Col>
        </Row>
    );
}

export default ProductSlider; 
