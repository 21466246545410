import React, { useEffect, useState, useCallback, Fragment } from "react";
import { Col, Row, FormGroup, FormFeedback } from "reactstrap";
import Form from "react-bootstrap/Form";
import Text from "../general/text";
import { Button } from "react-bootstrap";
import { callApiWithGenerateToken } from "../../api/api";

import { Link } from "react-router-dom";
var config = require("../../Config2.js");
export default function MyInfo(props) {
  const fName = localStorage.getItem("fname");
  const lName = localStorage.getItem("lname");
  const userEmail = localStorage.getItem("email");
  const userPhone = localStorage.getItem("phone");
  const city = localStorage.getItem("country");
  const location = localStorage.getItem("address");
  const zipcode = localStorage.getItem("zipcode");
  const id = localStorage.getItem("id");
  const [validated, setValidated] = useState(false);
  const [phone, setPhone] = useState(userPhone);
  const [fname, setFname] = useState(fName);
  const [lname, setLname] = useState(lName);
  const [email, setEmail] = useState(userEmail);
  const [country, setCountry] = useState(city);
  const [address, setAddress] = useState(location);
  const [postalCode, setPostalCode] = useState(zipcode);
  const [message, setMessage] = useState("");
  const [change, setChange] = useState(false);
  const changeHandler = () => {
    setChange(!change);
    setMessage("")
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    setMessage("");

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    var dataApi = {
      type: "editMyProfile",
      lang: 1,
      fname: fname,
      lname: lname,
      phone: phone,
      email: email,
      country:country,
      postalCode:postalCode,
      address:address,
      userId: id,
    };
    var response = await callApiWithGenerateToken(
      config.DOMAIN,
      dataApi,
      "POST"
    );
    if (response.code == 200) {
      setChange(!change)
      localStorage.setItem("fname", fname)
      localStorage.setItem('lname', lname)
      localStorage.setItem('phone', phone)
      localStorage.setItem("country",country);
      localStorage.setItem("address",address);
      localStorage.setItem("zipcode",postalCode);
    }
    setMessage(response.msg);
    event.preventDefault();
    event.stopPropagation();
    return;

    // if (response.msg == 'Added') {
    //     // setPhone('')
    //     // setFname('')
    //     // setEmail('')
    //     // setLname('')
    //     // setMessage('')
    //     // setShowMessage(true)
    // }
    // // setIsOpen(false)
  };

  return (
    <div className='myacc'>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="">
          <Col sm={12} md={6} className={"mt-2 mt-lg-3"}>
            <Form.Group className="mb-3">
              <p className="body-text-myAcc"> الاسم الاول</p>
              {change ? (
                <Form.Control
                  required
                  className=" checkout-form-input"
                  name="fistName"
                  placeholder="الاسم الاول"
                  type="text"
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                />
              ) : (
                <Form.Control
                  className=" checkout-form-input"
                  name="fistName"
                  type="text"
                  value={fname}
                  disabled
                />
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <p className="body-text-myAcc">الاسم الثاني</p>
              {change ? (
                <Form.Control
                  required
                  className=" checkout-form-input"
                  name="lastName"
                  placeholder="الاسم الثاني"
                  type="text"
                  value={lname}
                  onChange={(e) => setLname(e.target.value)}
                />
              ) : (
                <Form.Control
                  disabled
                  className=" checkout-form-input"
                  name="lastName"
                  type="text"
                  value={lname}
                />
              )}
            </Form.Group>
            <FormGroup className="mb-3">
              <p className="body-text-myAcc">رقم الهاتف</p>
              {change ? (
                <Form.Control
                  required
                  id="phone"
                  name="phone"
                  placeholder="رقم الهاتف"
                  type="number"
                  className=" checkout-form-input"
                  value={phone}
                  onChange={(e) => setPhone(`${e.target.value}`)}
                />
              ) : (
                <Form.Control
                  disabled
                  id="phone"
                  name="phone"
                  type="text"
                  className=" checkout-form-input"
                  value={phone}
                />

              )}
            </FormGroup>
            <FormGroup className="mb-3">
              <p className="body-text-myAcc">البريد الالكتروني</p>
              {change ? (
                <Fragment>
                  <Form.Control
                    disabled
                    name="email"
                    type="email"
                    className="checkout-form-input not-allowed"
                    value={email}
                  />

                </Fragment>
              ) : (
                <Form.Control
                  disabled
                  name="email"
                  type="email"
                  className="checkout-form-input not-allowed"
                  value={email}
                />
              )}
            </FormGroup>
          </Col>
          <Col xs={1} className="d-none d-lg-block">
            <div className="d-flex divider-vertical">
              <div className="vr"></div>
            </div>
          </Col>
          <Col className={"mt-2 mt-lg-3"}>

            <FormGroup >
              <p className="body-text-myAcc"> المدينة</p>
              {change ? <Form.Control
                required
                id="country"
                name="country"
                placeholder="المدينة"
                type="text"
                className=" checkout-form-input"
                value={country}
                onChange={(e) => setCountry(`${e.target.value}`)}
              /> :
                <Form.Control
                  disabled
                  id="country"
                  name="country"
                  placeholder="المدينة"
                  type="text"
                  className=" checkout-form-input"
                  value={country}
                  onChange={(e) => setCountry(`${e.target.value}`)}
                />
              }
            </FormGroup>

            <FormGroup className="mb-3">
              <p className="body-text-myAcc">العنوان </p>
              {change ?
                <Form.Control
                  required

                  name="address"
                  placeholder="العنوان"
                  type="text"
                  className="checkout-form-input"
                  value={address}
                  onChange={(e) => setAddress(`${e.target.value}`)}
                /> :
                <Form.Control
                  disabled
                  name="address"
                  placeholder="العنوان"
                  type="text"
                  className="checkout-form-input"
                  value={address}
                  onChange={(e) => setAddress(`${e.target.value}`)}
                />}
            </FormGroup>

            <FormGroup className="mb-3">
              <p className="body-text-myAcc">الرمز البريدي</p>
              {change ? <Form.Control
                required
                name="postalCode"
                placeholder="الرمز البريدي"
                type="number"
                className="checkout-form-input"
                value={postalCode}
                onChange={(e) => setPostalCode(`${e.target.value}`)}
              /> :
                <Form.Control
                  disabled
                  name="postalCode"
                  placeholder="الرمز البريدي"
                  type="number"
                  className="checkout-form-input"
                  value={postalCode}
                  onChange={(e) => setPostalCode(`${e.target.value}`)}
                />
              }
            </FormGroup>
          </Col>
        </Row>
        <Row className="row-buttons">
          {!change ? (
            <FormGroup className="mb-3">
              <Button
                variant="light"
                className="myAccount-button"
                onClick={changeHandler}
                type="reset"
              >
                تعديل
              </Button>
            </FormGroup>
          ) : (
            <div>
              <Button variant="light" type="submit" className="myAccount-button">
                تاكيد
              </Button>
              <Button
                variant="outline-light"
                onClick={changeHandler}
                className="myAccount-button"
              >
                الغاء
              </Button>
            </div>
          )}
          <div className="form-message"> {message}</div>
        </Row>
      </Form>

    </div>
  );
}
