import React, { useState } from "react";



const HomIcon = () => {

return (
<svg id="home_black_24dp_1_" data-name="home_black_24dp (1)" xmlns="http://www.w3.org/2000/svg" width="30.962" height="30.962" viewBox="0 0 30.962 30.962" fill="currentColor" >
  <path id="Path_19753" data-name="Path 19753" d="M30.962,0H0V30.962H30.962Z" fill="rgba(0,0,0,0)"/>
  <path id="Path_19754" data-name="Path 19754" d="M17.481,24.931v-7.74h-5.16v7.74H5.87V14.611H2L14.9,3,27.8,14.611h-3.87V24.931Z" transform="translate(0.58 0.87)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
</svg>

)

}

export default HomIcon
