import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import LoginSocial from "./loginSocial";
import { callApiWithGenerateToken } from "../../api/api";
import { useNavigate } from "react-router-dom";

var config = require("../../Config2.js");

function SignUp(state) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  async function handleSubmit(event) {
    
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    setMessage("");
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    } else if (password !== confirmPassword) {
      event.preventDefault();
      event.stopPropagation();
      setMessage("كلمة السر يجب ان تكون متطابقة");
      return;
    }
    var signUp = await callApiWithGenerateToken(
      config.DOMAIN,
      {
        type: "createAccount",
        lang: 1,
        fname: firstName,
        lname: lastName,
        emailAdress: email,
        password: password,
      },
      "POST"
    );
    const data = signUp.data;
    if (signUp.code === 200) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("fname", data.fname);
      localStorage.setItem("lname", data.lname);
      localStorage.setItem("phone", data.phone);
      localStorage.setItem("email", data.userEmail);
      localStorage.removeItem("cart");
      localStorage.removeItem("orderId")
      localStorage.removeItem("cuppon");
      localStorage.removeItem("cType");
      window.location.reload();
    }
    else {
      setMessage(signUp.msg)
    }
  }
  return (
    <>
      <div>
        <Form
           noValidate
           validated={validated}
           className="login-Form"
           onSubmit={handleSubmit}
        >
          {/* <LoginSocial /> */}
          <div className="row">
            <div className="col-12 col-md-12 col-lg-6 ">
              <Form.Group className="mb-3 ">
                <Form.Label className="pb-2">الاسم الاول</Form.Label>
                <Form.Control
                  required
                  name="fistName"
                  type="text"
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                                    هذا الحقل مطلوب
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-12 col-md-12 col-lg-6">
              <Form.Group className="mb-3 ">
                <Form.Label className="pb-2">الاسم الثاني </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="lastName"
                  onChange={(e) => setLastName(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  هذا الحقل مطلوب
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <Form.Group className="mt-3 ">
            <Form.Label className="pb-2 ">البريد الالكتروني</Form.Label>
            <Form.Control
              required
              type="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              ادخل بريد الكتروني صحيح
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3 ">
            <Form.Label className="pb-2 ">كلمة السر</Form.Label>
            <Form.Control
              required
              type="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
            هذا الحقل مطلوب
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mt-3 ">
            <Form.Label className="pb-2">إعادة كلمة السر</Form.Label>
            <Form.Control
              required
              type="password"
              name="confirmPassword"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
            هذا الحقل مطلوب
            </Form.Control.Feedback>
            <div className="form-validation-message"> {message}</div>
          </Form.Group>

          <Button
            className="mt-4 information-Control gradient-button-view gradient-button"
            type="submit"
          >
            الدخول
          </Button>
          <Form.Group className="mt-3  ">
            <div className="row">
              <div className="col-6 ">
                <p>هل تمتلك حساب؟</p>
              </div>
              <div className="col-6 froget-password ">
                <a
                  href="#"
                  onClick={() => {
                    state.changeShowPage(1);
                  }}
                  className="mb-3 froget-password"
                >
                  دخول
                </a>
              </div>
            </div>
          </Form.Group>
        </Form>
      </div>
    </>
  );
}

export default SignUp;
