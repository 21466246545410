import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Text from '../general/text';
import Title from '../general/title';

function Partners(props) {
    const { title, partners } = props
    const partnersData = () => {
        if (partners != null && partners != undefined && partners != '') {
            return partners.map((rowData, index) => {
                return (
                    <Col sm={6} lg={4} key={index}>
                        <div className='text-center'>
                            <div className='about-partners-img'>
                                <img src={rowData.image} className="img-fluid" alt="" />
                            </div>
                            <div className='mt-5 px-md-5'>
                                <Text text={rowData.name} />
                            </div>
                        </div>
                    </Col>
                )
            })
        }
    }

    return (
        <Container className='padding-top mb-5'>
            <Row>
                <Col md={12} className="mt-4">
                    <Title title={title} />
                    <Row className='mt-5'>
                        {partnersData()}
                    </Row>
                </Col>

            </Row>
        </Container>
    );
}

export default Partners;
