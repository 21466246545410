import React, { useCallback, useState, useEffect } from 'react';
import { PhoneAndroid, ExpandMore, ExpandLess } from '@mui/icons-material';

function Category(props) {

  const [categoryData, setCategoryData] = useState(props.categoryData);
  const [parent, setParent] = useState(props.category[0].id);
  const [sub, setSub] = useState(9);


  const changeCategory = (value, level) => {
    if (level == 1) {
      props.getData(value, 1)
      setSub('')
      setParent(value)
    } else {
      props.getData(value, 1)
      setSub(value)
    }

  }



  const CategoryList = () => {
    return (
      props.category.map((element, index) => (

        <div className='category-list'>

          <div className={parent == element.id ? 'row-two category-item' : 'row-two'}>

            <div onClick={() => changeCategory(element.id, 1)} className={parent == element.id ? 'category-item-size' : 'category-item-size'} >
              {element.name}
            </div>

            <div onClick={() => changeCategory(element.id, 1)} className={parent == element.id ? 'category-expand' : 'category-expand'} >
              {parent == element.id ? <ExpandLess className='category-icone' /> : <ExpandMore className='category-icone' />}
            </div>
          </div>

          {element.child.length && parent == element.id ?
            <div>
              {element.child.map((item, index) => (
                <div className={sub == item.id ? 'category-list-sub-active' : 'category-list-sub'} onClick={() => changeCategory(item.id, 2)}>
                  {item.name}
                </div>
              )
              )}
            </div>
            : null}
        </div>
      ))
    )
  }
  return (
    <div className='category-all'>
      <div className='Category-box'>
        {props.category && <CategoryList />}
      </div>
    </div>
  );
}

export default Category;
