import React, { useState, useEffect, Fragment } from 'react'
import { callApi, callApiWithGenerateToken, callAsyncAPI } from "../../api/api";
import { Collapse, CardBody, Card, CardHeader, Row, Col, Button, Input } from "reactstrap";
import FilterFramesIcon from '@mui/icons-material/FilterFrames';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { confirmAlert } from 'react-confirm-alert';
import Loading from '../Loading';
import CancelIcon from '@mui/icons-material/Cancel';
import {Container} from 'reactstrap';
import loadingIMG from "../../loadingIMG.gif"
var config = require("../../Config2.js");

export default function MyOrders() {


  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(0);
  const [loading, setLoading] = useState(1);
  const [pinOpen, setPinOpen] = useState(0);
  const [codes, setCodes] = useState([]);
  const token = localStorage.getItem("token")
  const copyHandler = (e) => {
    const txt = e.currentTarget.getAttribute('data-set');
    navigator.clipboard.writeText(txt);

  }
  const codesAlert = (e) => {
    const data = e.currentTarget.getAttribute('data-set');

    const index = codes.findIndex((element, index) => {
      if (data == element.id) {
        return true;
      }
    })
    let content = codes[index].cards.map((element) => {
      return (
        <div>
          <Row>
            <Col sm={9}>
              <p>{element.name}</p>
            </Col>
            <Col sm={2}>
              <p className='pin-code' >{element.code}</p>
              
            </Col>
            <Col sm={1}>
            <ContentCopyIcon className='pin-cursor' data-set={element.code} onClick={copyHandler} />
            </Col>
          </Row>
          </div>
      )
      
    },
    
    );
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-u'>
            {content}
          </div>
        
          )

      }
    });
  }
  useEffect(() => {
    
    const fetch = async () => {
      let data = [];
      
      
      var orders = await callApiWithGenerateToken(
        config.DOMAIN,
        { type: "getMyOrders", limit: 20, page: 1, token: token },
        "POST"
      );
      const info = orders.msg;

      let all = [];
      let od = [];
      for (let i = 0; i < info.length; i++) {

        
        all.push({
          id: info[i].id,
          total: info[i].total_after,
          date: info[i].entryDate.split(" ")[0],
          info:[],
          discount: info[i].discount,
          status:info[i].status
        });

      


      }
      setItems(all);
      setCodes(od)
      setLoading(0);
    }
    fetch();
  }, []);
  const openHandler = async (id) => {
    if (id == open) {
      setOpen(0);

    }
    else {
      var orderData = await callApiWithGenerateToken(
        config.DOMAIN,
        { type: "getUserOrderData", orderId: id, token: token },
        "POST"
      );
      const index = items.findIndex((order) => order.id==id);
      let orders = items
      orders[index].info = orderData.orderData;

      setItems(orders)
      setOpen(id)
      setLoading(0)
    }
  }
  const statusCheck= (val) => {
    if (val == 3) {
      return "مرفوض"
    }
    else if (val == 1) {
      return "مكتمل"
    } 
    else if (val == 2) {
      return "قيد المعالجة"
    } 
  }
  return (
    <div className='myacc row-margin'>
      {loading  && <div >
        <Container className='text-center'>
           <img className='loading-myOrders-start' src={loadingIMG}  />
        </Container>
  </div> 
  }
      {loading === 0 && items.length > 0 && items.map((element, index) => {
        return (



          <Row >
            <Card className='my-orders-card' key={element.id}>
              <CardHeader data-set={element.id} className='my-orders-card-color' onClick={()=>openHandler(element.id)}>
                <Row>
                  <Col sm={11}>
                    <Row><div className='myorders-card-header'> <span className='bold'>رقم الطلب</span>  {element.id} </div></Row>
                    <Row><div className='myorders-card-header'><span className='bold'>السعر الكلي</span>  {element.total} </div></Row>
                    <Row><div className='myorders-card-header'><span className='bold'>حالة الطلب</span>  {statusCheck(element.status)} </div></Row>

                    {element.discount != 0 ? <Row><div className='myorders-card-header'> <span className='bold'> قيمة الخصم</span> {element.discount} </div></Row> : ""}
                    <Row><div className='myorders-card-header bold'>{element.date}</div></Row>

                  </Col>
                  <Col sm={1} className='my-orders-arrow'>

                    {open === element.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </Col>
          </Row>



              </CardHeader>
              <Collapse isOpen={open === element.id}>

                <CardBody className='my-orders-card-color'>
                  {element.info.map((data, ind) => {
                    return (

                      <Row className='my-orders-card'>

                        <Col sm={1}>
                          <FilterFramesIcon />
                        </Col>
                        <Col sm={9} >
                          <div>{data.product.productName} </div>
                          <div>{data.product.productPrice} </div>
                          <div>x {data.product.qty}</div>
                        </Col>
                        {/* <Col sm={2} className='my-orders-arrow' >
                          {data.cards && <div className='pin-container'>

                            <Button className='pin-button' data-set={data.product.EzyPayOrderId} onClick={codesAlert} >Pin code</Button>

                          </div>}
                        </Col> */}
                      </Row>


                    )
                  })}
                </CardBody>
              </Collapse>
            </Card>


          </Row>
        )
      })}
      {
        loading == 0 && items.length === 0 && <div className='no-orders'>
          لا يوجد لديك اي طلبات
        </div>
      }
    </div>
  )
}
