import React, { useEffect, useState, useCallback } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { toAbsoluteUrl } from '../AssetHelpers'
import Banner from '../component/general/banner';
import BreadcrumbComp from '../component/general/breadcrumb';
import Text from '../component/general/text';
import Title from '../component/general/title';
import ProductCard from '../component/product/productCard';
import ProductSlider from '../component/product/productSlider';
import { callApiWithGenerateToken } from '../api/api'
import { useParams } from 'react-router';
import Loading from '../component/Loading';
import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';


var config = require('../Config2.js')
function ProductInfo(props) {
  const { cart, setCart, count, setCount, getAllCartData } = props;

  const img = toAbsoluteUrl("/media/img/bg-about.png")
  const imgBanner = toAbsoluteUrl("/media/img/bannerProductInfo.png")

  const [items] = useState([]);
  const [bannerImg, setBannerImg] = useState('');
  const { id } = useParams();
  const [related, setRelated] = useState([]);
  const [info, setInfo] = useState([])
  const [verifyQuantity, setVerifyQuantity] = useState(true);
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState('0')

  const [breadcrumArr, setBreadcrumArr] = useState([
    {
      label: 'المنتجات',
      link: 'Products'
    },
  ])
  const logResult = useCallback(() => {
    return 2 + 2;
  }, []); //logResult is memoized now.

  useEffect(() => {
    getData()
    callApiWithGenerateToken(config.DOMAIN, { type: 'isQtyValid', token: window.TOKEN, productId: id, quantity: '1' }, 'POST').then((res) => {
      if (res.code == 201) {
        setVerifyQuantity(false)
      } else setVerifyQuantity(true)
    })
  }, [logResult, id]);

  const increment = (info) => {
    if (info?.['moreFields']['5_Text_5_5']) {
      if (count < info?.['moreFields']['5_Text_5_5']) {
        setCount(count + 1);
      }
      else {
        return
      }
    }
  }
  const decrement = () => {
    if (count == 1) {
      setCount(1)
    } else if (count == 0) {
      return;
    }
    else {
      setCount(count - 1)
    }
  }

  const getData = async () => {

    var productInfo = await callApiWithGenerateToken(config.DOMAIN, { type: 'getPostById', id: id }, 'POST');

    if (productInfo) {

      setBannerImg(productInfo.data.moreFields)
      var breadcrum = [...breadcrumArr]
      breadcrum = breadcrum.slice(0, 1)
      if (breadcrum.length == 1) {
        breadcrum.push({ label: productInfo.data.name, link: '' })
        setBreadcrumArr(breadcrum)
      }
      setInfo(productInfo.data)
      setRelated(productInfo.related)
      setPrice(productInfo['data']['moreFields']['12_Text_12_5'])
      getLocalStorage(productInfo.data.id)

    }
  }

  const getLocalStorage = (id) => {
    setCount(1)
    const item = localStorage.getItem('cart');
    var parsedData = []
    if (item) {
      parsedData = JSON.parse(item)
    }
    else {
      parsedData = [];
    }

    if (parsedData) {
      const found = parsedData.itemInCart.find((item) => id == item.id);
      if (found) {
        setCount(found.qty)
      }
    }
  }
  const handleAddToCart = async (event, info) => {
    const newCart = cart;
    const cartItem = [...newCart.itemInCart]
    var itemInCart = cartItem.find((item) => item.id == info.id)
    items['id'] = info.id;
    items['qty'] = count;
    items['name'] = info.name;
    items['image'] = info.image;
    items['price'] = info['moreFields']['12_Text_12_5'] || 0
    items['allQty'] = info['moreFields']['5_Text_5_5'] || 1
    var quantityInfo = await callApiWithGenerateToken(config.DOMAIN, { type: 'isQtyValid', token: window.TOKEN, productId: info.id, quantity: count }, 'post');
    if (quantityInfo.code == 201) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui alert-box-view'>
              <div className="mb-4">
                <span className="alert-Succsess-icon failed"><ClearIcon /></span>
              </div>
              <h5>هذا المنتج غير متوفر بالكمية المطلوبة</h5>
              <br></br>
              <button type="button" className="btn btn-cancel mx-2" onClick={onClose}>إغلاق</button>
            </div>
          );
        }
      });
    }
    else {
      if (itemInCart) {
        itemInCart.qty = count;
        getAllCartData(cartItem)
      } else {
        itemInCart = {
          ...items,
          qty: count
        }
        cartItem.push(itemInCart);
        getAllCartData(cartItem)
      }

      if (cartItem) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className='custom-ui alert-box-view'>
                <div className="mb-4">
                  <span className="alert-Succsess-icon"><DoneIcon /></span>
                </div>
                <h5>تمت الإضافة بنجاح</h5>
                <p>هل تريد الذهاب الى سلة التسوق؟</p>
                <button type="button" className="btn btn-cancel mx-2" onClick={onClose}>لا</button>
                <button type="button" className="btn btn-card"
                  onClick={() => {
                    handleGoToCart();
                    onClose();
                  }}
                >
                  نعم
                </button>
              </div>
            );
          }
        });
      }
    }
  }
  const handleGoToCart = () => {
    window.location = '/Cart';

  }



  return (
    <div className='bg-img' style={{ backgroundImage: `url(${img})` }}>
      {(info != null && info != undefined && info != '') ?
        <>
          <div className='position-relative'>
            <Banner img={bannerImg?.['3_Image_3_5'] ? bannerImg['3_Image_3_5'] : imgBanner} />
            <BreadcrumbComp breadcrumData={breadcrumArr} />
            <div className='gradient-2'>
            </div>
          </div>
          <Container>
            <Title title={info.name} />
            <Row>
              <Col lg={4}>
                <ProductCard image={info?.image} fullContent={'0'} />
              </Col>
              <Col lg={8}>
                {info?.moreFields?.['2_MultiImages_2_5']['withURL'] && <ProductCard image={info.moreFields['2_MultiImages_2_5']['withURL']['0']} fullContent={'0'} imgH='imgH' />}
                <ProductSlider imgSlider={info?.moreFields?.['2_MultiImages_2_5']['withURL']} />
              </Col>
            </Row>

            <Row className='mt-5 align-items-baseline'>
              <Col lg={7} xl={8}  >
                {(verifyQuantity) ?
                  <><Row className='mt-5 align-items-end '>
                    <Col xs={5} sm={4} >
                      <Row className='color-white cart-count align-items-center'>
                        <Col xs={12}><Text className='textInfoItem' bold={'700'} text="الكمية" /></Col>
                        <div class className="content">
                          <span className="inc-dec" onClick={() => increment(info)}>+</span>

                          <div>{count}</div>

                          <span className="inc-dec" onClick={decrement}>-</span>
                        </div>

                      </Row>
                    </Col>
                    <Col xs={7} sm={8} className="text-start mt-3">

                      <div onClick={(event) => handleAddToCart(event, info)}>
                        <button className='btn btn-outline-white'>
                          أضف إلى عربة التسوق
                        </button>
                      </div>
                    </Col>
                  </Row>
                    <Row>
                      <Col md={9}>
                        <Title title={info.name} />
                      </Col>
                      <Col md={3} className='end'>
                        <div className='price-productsInfo2' >
                          {price + " JD"}
                        </div>
                      </Col>
                    </Row>


                    <Text text={info.description} type="tagHtml" /></> : <><br /><br /><Row>
                      <Col className='col-8'><Title title={info.name} /></Col>
                      <Col className='divNoItem' ><p className="mt-3 textNoItem" bold={'700'} >هذا المنتج غير متوفر حالياً</p></Col>
                    </Row>
                    <Text text={info.description} type="tagHtml" /></>
                }




              </Col>
              {related != null && related != undefined && related.length > 1 &&
                <Col lg={5} xl={4} className={'mx-auto '}>

                  <Text className='textInfoItem ' text={'منتجات مشابهة'} bold={'700'} />
                  <div className='infoprodectCard '>
                    {related?.map((rowData, index) => {
                      return (
                        <ProductCard image={rowData?.image}
                          // number={rowData.moreFields?.['1_Text_1_5']}
                          fullContent={'1'}
                          title={rowData.name}
                          description={rowData.description}
                          numberLetters={120} id={rowData?.id}
                          price={rowData['moreFields']['12_Text_12_5']}
                        />

                      )
                    })}
                  </div>

                </Col>
              }
            </Row>
          </Container>
        </>
        : <Loading />}


    </div>

  );
}

export default ProductInfo;
