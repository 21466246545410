import React, { useState } from "react";
import Slider from "react-slick";
import GamesBoxes from "./GamesBoxes";
import GamesBoxesGeneral from "./GamesBoxGeneral";
import TournamentsCard from "./TournamentsCard";

const SliderTournaments = (props) => {
    const { type } = props
    const [cardActive, setCardActive] = useState(1)
    const handleCardActive = (active) => {
        if (cardActive != active) {
            setCardActive(active)
        }
    }
    const settings_1 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        rtl: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]

    };
    const settings_2 = {
        infinite: false,
        nav: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        type == 'tournaments' ?
            <Slider {...settings_1} className="slider-tournaments">
                <TournamentsCard cardActive={cardActive} id={1} handleCardActive={() => handleCardActive(1)} />
                <TournamentsCard cardActive={cardActive} id={2} handleCardActive={() => handleCardActive(2)} />
                <TournamentsCard cardActive={cardActive} id={3} handleCardActive={() => handleCardActive(3)} />
                <TournamentsCard />
                <TournamentsCard />
            </Slider>
            :
            <Slider {...settings_2} >
                <GamesBoxesGeneral />
                <GamesBoxesGeneral />
                <GamesBoxesGeneral />
                <GamesBoxesGeneral />
                <GamesBoxesGeneral />
                <GamesBoxesGeneral />
                <GamesBoxesGeneral />
                <GamesBoxesGeneral />
                <GamesBoxesGeneral />
            </Slider>


    )

}

export default SliderTournaments
