import React, { useEffect, useState, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
import { toAbsoluteUrl } from '../AssetHelpers'
import Banner from '../component/general/banner';
import BreadcrumbComp from '../component/general/breadcrumb';
import Text from '../component/general/text';
import Title from '../component/general/title';
import { confirmAlert } from 'react-confirm-alert';
import { callApiWithGenerateToken } from '../api/api'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";

import Loading from '../component/Loading';
import CartContent from '../component/cart/cartContent';
import OrderSummary from '../component/cart/orderSummary';
import ClearIcon from '@mui/icons-material/Clear';
import DiscountCode from '../component/cart/discountCode';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';


var config = require('../Config2.js')
function Cart(props) {
    const token = localStorage.getItem("token");
    const { cart, setCart, count, setCount, getAllCartData, tax, orderId, setOrderId,code, setCode, submitCouponCode, msg } = props;

    const img = toAbsoluteUrl("/media/img/bg-about.png")
    const imgBanner = toAbsoluteUrl("/media/img/bannerNews.png")
    const navigate = useNavigate();
    const [bannerImg, setBannerImg] = useState('');
    const [breadcrumArr, setBreadcrumArr] = useState([
        {
            label: 'سلة التسوق',
            link: ''
        },
    ])

    const logResult = useCallback(() => {
        return 2 + 2;
    }, []); //logResult is memoized now.
    useEffect(() => {
        localStorage.getItem('cart')
        // getData()
    }, [logResult, cart]);


    const getProductId = () => {
        var data = {};
        cart.itemInCart.forEach(element => {
            data[element.id] = { 'quantity': element.qty }
        });
        return data;
    }

    const getData = async () => {
        getProductId()
        var dataApi = {
            "type": "addOrder",
            "token": token,
            "orderId": orderId,
            "couponCode": code,
            "products": getProductId(),

        }
        var orderData = await callApiWithGenerateToken(config.DOMAIN, dataApi, 'POST');
        if (orderData.code ===200) {
            setOrderId(orderData.orderId)
            localStorage.setItem('orderId', orderData.orderId);
            navigate("/Checkout")
        }
        if(orderData.code==201){
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui alert-box-view'>
                    <div className="mb-4">
                      <span className="alert-Succsess-icon failed"><ClearIcon /></span>
                    </div>
                    <h5>هذا المنتج غير متوفر بالكمية المطلوبة</h5>
                    <br></br>
                    <button type="button" className="btn btn-cancel mx-2" onClick={onClose}>إغلاق</button>
                  </div>
                );
              }
            });
            return;
          }


    }

    return (

        <div className='bg-img cart-page' style={{ backgroundImage: `url(${img})` }}>
            {cart ?
                <>
                    <div className='position-relative'>
                        <Banner img={imgBanner} />
                        <BreadcrumbComp breadcrumData={breadcrumArr} />
                        <div className='gradient-2'>
                        </div>
                    </div>
                    <Container>
                        <Title title={'سلة التسوق'} />
                        {cart.itemInCart.length > 0 ?
                            <Row>
                                <Col lg={8}>
                                    <Row className='cart-header d-none d-sm-flex'>
                                        <Col xs={5}>
                                            <Text text="اسم المنتج" />
                                        </Col>
                                        <Col xs={3}>
                                            <Text text="السعر" />
                                        </Col>
                                        <Col xs={3}>
                                            <Text text="الكمية" />
                                        </Col>
                                        <Col xs={1}></Col>
                                    </Row>
                                    {cart?.itemInCart?.map((rowData, index) => {
                                        return (
                                            <CartContent id={rowData.id}
                                                image={rowData.image}
                                                qty={rowData.qty}
                                                name={rowData.name}
                                                price={rowData.price}
                                                allQty={rowData.allQty}
                                                cart={cart}
                                                setCart={setCart}
                                                item={rowData}
                                                count={count}
                                                setCount={setCount}
                                                getAllCartData={getAllCartData}
                                            />
                                        )
                                    })}

                                </Col>
                                <Col md={12} lg={4} className="my-5 mt-lg-0 pe-lg-5">
                                    <OrderSummary cart={cart} getData={getData} tax={tax} />
                                    <DiscountCode code={code} setCode={setCode} submitCouponCode={submitCouponCode} msg={msg} />
                                </Col>
                            </Row>
                            :
                            <Row className='justify-content-center align-items-center'>
                                <Col md={12} className="my-4 text-center no-data no-cart-data">
                                    <ShoppingCartOutlinedIcon className="color-white" />
                                    <Text text="لا يوجد منتجات في سلة التسوق" />
                                </Col>
                            </Row>
                        }
                    </Container>

                    
                </>
                : <Loading />}

        </div>

    );
}

export default Cart;
