import React, { useCallback, useState, useEffect } from 'react';
import { Col, Container, Row } from "reactstrap";
import { PhoneAndroid, ExpandMore, ExpandLess } from '@mui/icons-material';
import { toAbsoluteUrl } from "../../AssetHelpers";
import Slider from "react-slick";
function GamesBoxes(props) {
  const settings = {
    dots: true,
    infinite: true,
    nav: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const Icongame = toAbsoluteUrl("media/img/Icongame.svg")
  const testCard = toAbsoluteUrl("media/img/Card.svg")

  // const changeCategory = (value, level) => {
  //   if (level == 1) {
  //     props.getData(value, 1)
  //     setSub('')
  //     setParent(value)
  //   } else {
  //     props.getData(value, 1)
  //     setSub(value)
  //   }

  // }



  const CategoryList = () => {
    // return (
    //   props.category.map((element, index) => (

    //     <div className='category-list'>

    //       <div className={parent == element.id ? 'row-two category-item' : 'row-two'}>

    //         <div onClick={() => changeCategory(element.id, 1)} className={parent == element.id ? 'category-item-size' : 'category-item-size'} >
    //           {element.name}
    //         </div>

    //         <div onClick={() => changeCategory(element.id, 1)} className={parent == element.id ? 'category-expand' : 'category-expand'} >
    //           {parent == element.id ? <ExpandLess className='category-icone' /> : <ExpandMore className='category-icone' />}
    //         </div>
    //       </div>

    //       {element.child.length && parent == element.id ?
    //         <div>
    //           {element.child.map((item, index) => (
    //             <div className={sub == item.id ? 'category-list-sub-active' : 'category-list-sub'} onClick={() => changeCategory(item.id, 2)}>
    //               {item.name}
    //             </div>
    //           )
    //           )}
    //         </div>
    //         : null}
    //     </div>
    //   ))
    // )
  }
  return (
    <Row>
      <div className="similar__shows">
        <div className="btn__title-Games">
          <img src={Icongame} /> <h5>الألعاب الاكثر شهرة </h5>
        </div>
        <div className="similar-Games">

          <div className="col-12">
            <Row >
              <Slider {...settings}>

                <Col xs={6} xl={2} lg={3} md={4} className="Col-Games col-sm-4">
                  <img src={testCard} className="img-fluid" />
                </Col>
                <Col xs={6} xl={2} lg={3} md={4} className="Col-Games">
                  <img src={testCard} className="img-fluid" />
                </Col>

                <Col xs={6} xl={2} lg={3} md={4} className="Col-Games">
                  <img src={testCard} className="img-fluid" />
                </Col>

                <Col xs={6} xl={2} lg={3} md={4} className="Col-Games">
                  <img src={testCard} className="img-fluid" />
                </Col>

                <Col xs={6} xl={2} lg={3} md={4} className="Col-Games">
                  <img src={testCard} className="img-fluid" />
                </Col>

                <Col xs={6} xl={2} lg={3} md={4} className="Col-Games">
                  <img src={testCard} className="img-fluid" />
                </Col>
                <Col xs={6} xl={2} lg={3} md={4} className="Col-Games col-sm-4">
                  <img src={testCard} className="img-fluid" />
                </Col>
                <Col xs={6} xl={2} lg={3} md={4} className="Col-Games">
                  <img src={testCard} className="img-fluid" />
                </Col>

                <Col xs={6} xl={2} lg={3} md={4} className="Col-Games">
                  <img src={testCard} className="img-fluid" />
                </Col>

                <Col xs={6} xl={2} lg={3} md={4} className="Col-Games">
                  <img src={testCard} className="img-fluid" />
                </Col>

                <Col xs={6} xl={2} lg={3} md={4} className="Col-Games">
                  <img src={testCard} className="img-fluid" />
                </Col>

                <Col xs={6} xl={2} lg={3} md={4} className="Col-Games">
                  <img src={testCard} className="img-fluid" />
                </Col>


              </Slider>




            </Row>

          </div>
        </div>
      </div>
    </Row>






  );
}

export default GamesBoxes;
