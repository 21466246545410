
import React, { useEffect, useState, useCallback } from 'react';
import { CardBody, Row, Col, Card, Button } from 'reactstrap';
import { toAbsoluteUrl } from '../../AssetHelpers'
import Cards from '../general/cards';
import Text from '../general/text';

function LatestNews(props) {
    const { latest } = props
    const img = toAbsoluteUrl("/media/img/wallpaperflare.com_wallpaper (22).png");
    const img_not_found = toAbsoluteUrl("/media/img/not_found.png")
    const [news, setNews] = useState([
        { id: 0, categoryId: '', image: '', title: '', description: '' }
    ])
    const [active, setActive] = useState('')


    const activeNews = (index, data) => {
        setActive(index)
        setNews(data)

    }
    const logResult = useCallback(() => {
        return 2 + 2;
    }, []); //logResult is memoized now.
    useEffect(() => {
        setActive(latest[0].id)
        setNews({ id: latest[0].id, categoryId:latest[0].categoryId, image: latest[0].image, title: latest[0].name, description: latest[0].description })
    }, [logResult])


    return (
        <div>
            <Row>
                <Col lg={6} xl={5} className="mb-5 mb-lg-0">
                    <Card className='latest-news'>
                        <CardBody >
                            {latest != null && latest != undefined && latest != '' && latest.map((rowData, index) => {
                                var description = rowData.description.substring(0, 120) + ' ...';

                                return (
                                   (index<4) ? <Row  key={index} className={active == rowData.id ? 'align-items-center hover-news news-active news-card ' : 'align-items-center hover-news news-card '} onClick={() => { activeNews(rowData.id, { id: rowData.id, categoryId: rowData.categoryId, image: rowData.image != '' ? rowData.image : img_not_found, title: rowData['name'], description: rowData.description }) }}>
                                        <Col xs={3}><img src={rowData.image != '' ? rowData.image : img_not_found} className='img-fluid' /></Col>
                                        <Col xs={9}>
                                            <h5 className='color-white'>{rowData?.['name']}</h5>
                                            <Text text={description} type={'tagHtml'} />
                                        </Col>
                                    </Row>:<></>
                                )
                            })}

                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6} xl={7}>
                    {active == news.id && <Cards id={news.id} type={news.categoryId} description={news.description} numberLetters={280} title={news.title} image={news.image}  />}
                </Col>
            </Row>
        </div>
    );
}

export default LatestNews;