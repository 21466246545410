import { Button } from "react-bootstrap";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { callApiWithGenerateToken } from "../../api/api";
import forgetPassword from "../../forgetPassword.png";
var config = require("../../Config2.js");

function ForgetPassword(state) {
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setMessage("");
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    var forget = await callApiWithGenerateToken(
      config.DOMAIN,
      { type: "forgetPassword",lang: 1, email: email },
      "POST"
    );
    if (forget.code === 200) {
      localStorage.setItem("forgetEmail",email);
      state.changeShowPage(4);
    }
    setMessage(forget.msg);
  }
  return (
    <>
      <div>
        <Form noValidate validated={validated} className="forget-password-form" onSubmit={handleSubmit}>
          <p>إعادة ضبط كلمة السر</p>
          <img src={forgetPassword} />
          <Form.Group className="mb-3 ">
            <Form.Label className="pb-2 ">ادخل البريد الالكتروني</Form.Label>
            <Form.Control
              required
              type="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">ادخل بريد الكتروني صالح</Form.Control.Feedback>
            <div className="form-validation-message">{message}</div>
          </Form.Group>
          
          <Button
            className=" forget-passwword-button gradient-button-view gradient-button"
            type="submit"
          >
            التالي
          </Button>
        </Form>
      </div>
    </>
  );
}

export default ForgetPassword;
