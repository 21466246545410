import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { callApiWithGenerateToken } from "../../api/api";
import checkCode from "../../checkCode.png";
var config = require("../../Config2.js");

function CheckCode(state) {
  const [code, setCode] = useState("");
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState("");
  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setMessage("");
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    const email = localStorage.getItem("forgetEmail");
    var check = await callApiWithGenerateToken(
      config.DOMAIN,
      { type: "validateReset",lang: 1, otp: code,email:email },
      "POST"
    );
    if (check.code === 200) {
      state.changeShowPage(5);
    } else {
      setMessage(check.msg);
    }
    //
  }

  return (
    <Form
      noValidate
      validated={validated}
      className="forget-password-form"
      onSubmit={handleSubmit}
    >
      <p>إعادة ضبط كلمة السر</p>
      <img src={checkCode} />
      <Form.Group className="mb-3">
        <Form.Label className="pb-2">ادخل رمز التأكيد</Form.Label>
        <Form.Control
          required
          name="code"
          onChange={(e) => setCode(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          هذا الحقل مطلوب
        </Form.Control.Feedback>
        <div className="form-validation-message"> {message}</div>
      </Form.Group>
      
      <Button
        type="submit"
        className=" forget-passwword-button gradient-button-view gradient-button"
      >
        التالي
      </Button>
    </Form>
  );
}

export default CheckCode;
