

import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Button } from 'reactstrap';
import classnames from 'classnames';
import Cards from './cards';
import Text from './text';
import { Link } from 'react-router-dom';

export default class VerticalTabs extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: this.props.tab[0].id,

    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      }, function () {
        this.props.getData(tab, 1, '0')
      });
    }
  }

  render() {
    return (
      <Row className='justify-content-between  '>
        <Col md={3} lg={2}>
          <Nav tabs variant="pills" className="flex-column nav-style">
            {this.props.tab.map((rowData, index) => {
              return (
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === rowData.id })}
                    onClick={() => { this.toggle(rowData.id); }}
                  >
                    {rowData.name}
                  </NavLink>
                </NavItem>
              )
            })}

          </Nav>
        </Col>
        <Col  md={9} lg={10} >
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId={this.state.activeTab}>
              <Row>
                {this.props.items != null && this.props.items != undefined && this.props.items.length > 0 ?
                  this.props.items.map((rowData, index) => {
                    if (rowData.parent == this.state.activeTab) {
                      return (
                        (this.props.items.length%2!==0)? <Col xs={12} lg={this.props.pageName == 'blog' && ((index == 2) && (this.props.items % 2 != 0)) ? 12 : 6} className="mb-2 px-3">
                          <Cards id={rowData.id} type={rowData.categoryId} description={rowData.description} numberLetters={150} title={rowData['name']} image={rowData.image} />
                        </Col>:
                        ((this.props.items.length-1)==index)?<Col xs={12} lg={6} className="mb-2 mx-auto px-3">
                        <Cards id={rowData.id} type={rowData.categoryId} description={rowData.description} numberLetters={150} title={rowData['name']} image={rowData.image} />
                      </Col>:<Col xs={12} lg={this.props.pageName == 'blog' && ((index == 2) && (this.props.items % 2 != 0)) ? 12 : 6} className="mb-2 px-3">
                        <Cards id={rowData.id} type={rowData.categoryId} description={rowData.description} numberLetters={150} title={rowData['name']} image={rowData.image} />
                      </Col>

                      )
                    }
                  })
                  :
                  <Col md={12} className="my-4 text-center no-data">
                    <Text text="لا يوجد بيانات لعرضها حاليا" />
                  </Col>

                }

                {this.props.loading == 1 && this.props.flagButton != this.props.page && this.props.items.length > 0 &&
                  <Col md={12} className="text-center my-5">
                    <Button className='btn btn-outline-white btn-size1' onClick={() => this.props.changePage()}>تحميل المزيد</Button>
                  </Col>
                }

              </Row>
            </TabPane>

          </TabContent>
        </Col>
      </Row>
    );
  }
}
