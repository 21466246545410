import React, { useCallback, useState, useEffect } from 'react';

import mainBkgProductP from "../../mainBkgProductP.png"
import bkgimgProductP from "../../bkgimgProductP.png"
import ProductCard from '../product/productCard';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Category from './category';
import BreadcrumbComp from '../general/breadcrumb';
import Banner from '../general/banner';

import {callApiWithGenerateToken} from '../../api/api'
import { Row, Col, Container} from 'reactstrap';

var config = require('../../Config2.js')   

function ProductShow() {

  const [page, setPage] = React.useState(1);
  const [category, setCategory] = useState('');
  const [items, setItems] = useState('');
  const [parent, setParent] = useState('');
  const [countPage, setCountPage] = useState('');
  const [price, setPrice] = useState('');
  const [breadcrumArr, setBreadcrumArr] = useState([
    {
      label: 'المنتجات',
      link: 'Product'
    },
  ]) 
  const logResult = useCallback(() => {
    return 2 + 2;
  }, []); //logResult is memoized now.

  useEffect(() => {
    getData(0,1)
    },[logResult]);

    const getData = async(parent = 0, pageNum = page)=>{
      setPage(pageNum)
      setParent(parent)
        var categoryData = await callApiWithGenerateToken(config.DOMAIN, {type: 'getProudcts', lang:1, limit:6,page:pageNum, parent:parent, getCat:1}, 'POST');
      setCategory(categoryData.category)
      setItems(categoryData.products)
      setCountPage(categoryData.entries.page)
      }

  const handleChange = (event, value) => {
    getData(parent, value)
    setPage(value);
  };

      const ItemsList = () => {
        return(
         
          items.map((element, index) => (
            <Col md= "6" xs="12">
              <div className="item-colomns">
              <ProductCard 
                    id={element.id}
                    wHight={1}
                    image={element.image}
                    number={element.id}
                    fullContent={'1'}
                    title={element.name}
                    description={element.description} />
              </div>
          </Col>

          ))
      
        )
      }
       

  return (
    <div className='backgcolor-Appcolor'>
    <div className='Home-Top' style={{ backgroundSize: 'cover', backgroundImage: `url(${mainBkgProductP})`}}>

    <div className='position-relative'>
            <Banner img={bkgimgProductP} />
            <BreadcrumbComp breadcrumData={breadcrumArr} />
            <div className='gradient-2'>
            </div> 
          </div>
 
 
                    

<div>
  <Container>
  <Row md="2"xs="1" >
  <Col md="2" xs="12">
  {category && <Category getData={getData} category={category}/>}

  </Col>


  <Col md="10" xs="12">
  <Row md="2" xs= "1">
  {items.length > 0 ? <ItemsList/> : 
  <Col md= "12" xs="12">
      <div className='notfound-product justifyContent marginTop-200px'>
      لا يوجد بيانات
     </div>
</Col>
  }
  </Row>

  </Col>

 
  <Col md="2" xs="2">
  </Col>


  <Col md="10" xs="10" className='height-150px'>


  
{countPage > 1 ?
     <Stack className='pagination-box' spacing={2}>
        <Pagination className='pagination-box ' count={countPage} page={page} onChange={handleChange} />
     </Stack> : null}

  </Col>


</Row>
</Container>

</div>
</div>
</div>
  );
}

export default ProductShow;