import React, { useEffect, useState, useCallback } from 'react';
import { Col, Input, Row } from 'reactstrap';
import { toAbsoluteUrl } from '../../AssetHelpers'
import Text from '../general/text';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link } from 'react-router-dom';
import { callApiWithGenerateToken } from '../../api/api'
import ClearIcon from '@mui/icons-material/Clear';

var config = require('../../Config2.js')
function CartContent(props) {
    const { cart , setCart, item, getAllCartData, allQty,id,price,name } = props;

    const img_not_found = toAbsoluteUrl("/media/img/not_found.png")
    const [count, setCount] = useState(props.qty)

    const logResult = useCallback(() => {
        return 2 + 2;
    }, []); //logResult is memoized now.

    useEffect(() => {
        const carts = JSON.parse(localStorage.getItem('cart'));
        if (carts) {
         setCart(carts);
        }
        
        // getData()
    }, [logResult]);

    const increment = async(id) => { 
      var productInfo = await callApiWithGenerateToken(config.DOMAIN, { type: 'isQtyValid', token: window.TOKEN,productId:id ,quantity:count+1},'post');
      if(productInfo.code==201){
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className='custom-ui alert-box-view'>
                <div className="mb-4">
                  <span className="alert-Succsess-icon failed"><ClearIcon /></span>
                </div>
                <h5>هذا المنتج غير متوفر بالكمية المطلوبة</h5>
                <br></br>
                <button type="button" className="btn btn-cancel mx-2" onClick={onClose}>إغلاق</button>
              </div>
            );
          }
        });
  
      }
      else{
        if (count < allQty) {
        var num = count + 1
        setCount(num)
        setQty(num)
        } else {
            setCount(allQty)
            setQty(allQty)
        }
      }}
    const decrement = () => {
        var num = count - 1
        if (count == 1) {
          setCount(1)
        } else {
          setCount(num)
          setQty(num)
        }
      }


      const setQty = (num) => {
        const newCart = cart;    
        const cartItem = [...newCart.itemInCart]
        const cupon = localStorage.getItem("cuppon");

        cartItem.find((items => items.id == item.id)).qty = num
        getAllCartData(cartItem)
      }

      const removeFromCart = (productToRemove) => {

        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui alert-box-view'>
                  <div className="mb-4">
                    <span className="alert-delete-icon">!</span>
                  </div>
                  <h5>هل أنت متأكد؟</h5>
                  <p>هل تريد حذف هذا المنتج؟</p>
                  <button type="button" className="btn btn-cancel mx-2" onClick={onClose}>لا</button>
                  <button type="button" className="btn btn-card"
                    onClick={() => {
                      handleRemoveFromCart(productToRemove);
                      onClose();
                    }}
                  >
                    نعم
                  </button>
                </div>
              );
            }
          });
      
    }

   const handleRemoveFromCart = (productToRemove) => {
    let itemInCart = [...cart.itemInCart]
    var removeItem = itemInCart.filter((product) =>  product !== productToRemove)
    getAllCartData(removeItem)
   }

    return (
        <Row className='mt-4 mt-lg-2 align-items-center cart-content'>
            <Col sm={5}>
                <Row className='align-items-center'>
                <Col xs={1} className="d-sm-none"><DeleteOutlineIcon className='color-white' onClick={() => removeFromCart(item)}/></Col>
                    <Col xs={3} sm={5}>
                        <Link to={'/productInfo/'+id} className='img-cart'><img src={props?.image ? props?.image : img_not_found} className="img-fluid" alt="..." /></Link>
                    </Col>
                    <Col xs={7} sm={7} className={"px-4"}>
                        <Link to={'/productInfo/'+id}><Text text={name} /></Link>
                    </Col>
                </Row>
            </Col>
            {/* <Text text="السعر : " /> */}
            <Col sm={3} className="my-4 my-sm-0 ">
                <Row className='px-5 px-sm-0 content myA '>
                    <Col xs={4} className='d-sm-none'><p className='myA' > السعر :</p></Col>
                    <Col xs={8} lg={12} className="color-white"><Text text={'$' + price} /></Col>
                </Row>
            </Col>
            <Col sm={3} className="mb-3">
                <Row className='color-white cart-count align-items-center px-5 px-sm-0 content myAq'>
                    <Col xs={4} sm={3} className='d-sm-none '><p className='myA my-auto' > الكمية :</p></Col>
                    <div xs={8} className='content'>
                    <Col xs={2} sm={3} lg={4} className="inc-dec" onClick={()=>{increment(props.id)}}>+</Col>
                    <Col xs={2} sm={3} lg={3} className='count text-center'>
                        <div className=' m-auto'>
                        {count}
                        </div>
                    </Col>
                    <Col xs={2} sm={3} lg={4} className="inc-dec" onClick={decrement}>-</Col>
                    </div>
                </Row>
            </Col>
            <Col sm={1} className="d-none d-sm-block mb-3"><DeleteOutlineIcon className='color-white' onClick={() => removeFromCart(item)} /></Col>
        </Row>
    );
}

export default CartContent;
