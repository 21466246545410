import React, { useCallback, useState, useEffect } from 'react';
import mainBkgProductP from "../mainBkgProductP.png"
import { callApiWithGenerateToken } from '../api/api'
import { Row, Col, Container } from 'reactstrap';
import BreadcrumbComp from '../component/general/breadcrumb';
import Banner from '../component/general/banner';
import VerticalTabs from '../component/general/verticalTabs';
import Title from '../component/general/title';
import Loading from '../component/Loading';
import { toAbsoluteUrl } from '../AssetHelpers'


var config = require('../Config2.js')

function Blog(props) {
  const imgBanner = toAbsoluteUrl("/media/img/bannerAllBlog.png")
  const data = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]
  const [bannerImg, setBannerImg] = useState('');
  const [category, setCategory] = useState('');
  const [items, setItems] = useState('');
  const [pageActive, setPageActive] = useState(1);
  const [parent, setParent] = useState(0);
  const [flagButton, setFlagButton] = useState(0);
  const [loading, setLoading] = useState(0)
  const [breadcrumArr, setBreadcrumArr] = useState([
    {
      label: 'المدونة',
      link: ''
    },
  ])
  const logResult = useCallback(() => {
    return 2 + 2;
  }, []); //logResult is memoized now.

  useEffect(() => {
    getData(parent, pageActive)
  }, [logResult]);




  const changePage = () => {
    getData(parent, pageActive + 1)
  }

  const getData = async (parent = 0, page = pageActive) => {
    setLoading(0)
    setPageActive(page)
    setParent(parent)
    var categoryData = await callApiWithGenerateToken(config.DOMAIN, { type: 'getBlogs', lang: 1, page: page, limit: 5, parent: parent, getCat: 1 }, 'POST');
    if(categoryData) {
    setBannerImg(categoryData.bannerInfo)
    setCategory(categoryData.category)
    if (page == 1) {
      setItems(categoryData.data)
    } else {
      setItems([...items, ...categoryData.data])

    }
    setFlagButton(categoryData.entries.page)
    setLoading(1)
  }
  }


  return (
    <div className='backgcolor-Appcolor'>
      <div className='Home-Top' style={{ backgroundSize: 'cover', backgroundImage: `url(${mainBkgProductP})` }}>
        {category != null && category != undefined && category != '' ?
          <>
            <div className='position-relative'>
              <Banner img={bannerImg?.image ? bannerImg.image : imgBanner} />
              <BreadcrumbComp breadcrumData={breadcrumArr} />
              <div className='gradient-2'></div>
            </div>
            <Container>
              <div className="mb-4">
                <Title title={'المدونة'} />
              </div>
              <Row>
                <Col md={12}>
                  <VerticalTabs loading={loading} tab={category} items={items} getData={getData} changePage={changePage} flagButton={flagButton} page={pageActive} pageName={'blog'} />
                </Col>
              </Row>
            </Container>
          </>
          :
          <Loading />
        }
      </div>
    </div>
  );
}

export default Blog;