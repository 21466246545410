import {Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';

import confirmPassword from '../../confimPassword.png';



function ConfirmPassword({close}) {

    return (
        <>
                <div className=''>
                    <Form className='confirm-message-form'>
                    <p>تم إعادة ضبط كلمة السر</p>
                    <img src={confirmPassword}/>
                        <Button  className=" gradient-button-view gradient-button" onClick={close}>
                            انهاء
                        </Button>
                    </Form>

                </div>
        </>
    );
}
export default ConfirmPassword;