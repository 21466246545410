import React, { useCallback, useState, useEffect } from 'react';
import { Col, Container, Row } from "reactstrap";
import Text from "../component/general/text";
import Banner from "../component/MasterPage/Banner";
import SideBar from "../component/sidebar/sidebar";
import { ProSidebarProvider } from 'react-pro-sidebar';
import { toAbsoluteUrl } from "../AssetHelpers";
import { MenuItem, } from "react-pro-sidebar";
import Slider from "react-slick";
import GamesBoxes from "../component/MasterPage/GamesBoxes";

function Games() {
const [data , setdata] = useState('')
const [title , setTitle] = useState('')
    const settings = {
        dots: true,
        infinite: true,
        nav: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 2,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const Icongame = toAbsoluteUrl("media/img/Icongame.svg")
    const testCard = toAbsoluteUrl("media/img/Card.svg")
    return (
        <ProSidebarProvider>
            <div className="d-flex ">
                <SideBar />
                <div className="w-100 overflow-hidden mt-5 Header_Games">
                    <Row>
                        <Col xs={12}>
                            <Banner />
                        </Col>
                    </Row>
                    <GamesBoxes title={title}  data={data}/>
                </div>
            </div>
        </ProSidebarProvider >

    )
}

export default Games;