import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";

import { callApiWithGenerateToken } from "../../api/api";

import CryptoJS from "crypto-js";

var config = require("../../Config2.js");
function Login(state) {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState("");
  const [checked, setChecked] = useState("");



  useEffect(() => {
    const chk = localStorage.getItem("checked");

    if (chk) {
      const rememberedEmail = localStorage.getItem("rEmail");
      if (rememberedEmail) {
      setEmail(rememberedEmail);
      const hashedPassword = localStorage.getItem("rPassword");
      const dec = CryptoJS.AES.decrypt(hashedPassword, "my-secret-key@123");
      const decryptedData = JSON.parse(dec.toString(CryptoJS.enc.Utf8));
      setPassword(decryptedData);
      setChecked(true);
      }
      else {
        setChecked(false);
      }
    }
  }, []);
  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    setMessage("");

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    var login = await callApiWithGenerateToken(
      config.DOMAIN,
      { type: "login", lang: 1, username: email, password: password },
      "POST"
    );
    const data = login.data;
    if (login.code === 200) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("fname", data.fname);
      localStorage.setItem("lname", data.lname);
      localStorage.setItem("phone", data.phone);
      localStorage.setItem("email", data.userEmail);
      localStorage.setItem("id",data.userId);
      localStorage.setItem("country",data.country);
      localStorage.setItem("address",data.address);
      localStorage.setItem("zipcode", data.postalCode);
      localStorage.removeItem("cart");
      localStorage.removeItem("orderId")
      localStorage.removeItem("cuppon");
      localStorage.removeItem("cType");
      if (checked) {
        localStorage.setItem("rEmail", data.userEmail);
        const hashedPassword = CryptoJS.AES.encrypt(
          JSON.stringify(password),
          "my-secret-key@123"
        ).toString();
        localStorage.setItem("rPassword", hashedPassword);
        localStorage.setItem("checked", true);
      }
      else {
        localStorage.removeItem("rPassword");
        localStorage.removeItem("rEmail");
        localStorage.removeItem("checked");
      }

      window.location.reload();
    } else {
      setMessage(login.msg);
      return;
    }
  }

  return (
    <>
      <Form
        noValidate
        validated={validated}
        className="login-Form"
        onSubmit={handleSubmit}
      >
        {/* <LoginSocial /> */}

        <Form.Group >
          <Form.Label className="pb-2 ">البريد الالكتروني</Form.Label>
          <Form.Control
            required
            value={email}
            name="email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />

          <Form.Control.Feedback type="invalid">
            ادخل بريد الكتروني صحيح
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-2 mt-2 ">
          <Form.Label className="pb-2 ">كلمة السر</Form.Label>
          <Form.Control
            required
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Form.Control.Feedback type="invalid">
            ادخل كلمة السر
          </Form.Control.Feedback>
        </Form.Group>
        <div className="form-validation-message"> {message}</div>
        <Form.Group className="mb-2  ">
          <div className="row">
            <div className="col-6  d-flex">
              <Form.Check
                type="checkbox"
                checked={checked}
                onChange={(event) => {
                  setChecked(!checked);
                }}
              />
              <p className="me-2">تذكرني</p>
            </div>
            <div className="col-12 col-md-12 col-lg-6 froget-password1 ">
              <a
                href="#"
                onClick={() => {
                  state.changeShowPage(3);
                }}
                className="mb-3 froget-password1 "
              >
                هل نسيت كلمة السر؟
              </a>
            </div>
          </div>
        </Form.Group>
        <Form.Group>
          <Button
          className=" information-Controll gradient-button-view gradient-button"
          type="submit"
        >
          الدخول
        </Button>
        </Form.Group>
        
        <Form.Group className="mt-2  ">
          <div className="row">
            <div className="col-6 ">
              <p>لا تمتلك حساب ؟</p>
            </div>
            <div className="col-6 froget-password ">
              <a
                href="#"
                onClick={() => {
                  state.changeShowPage(2);
                }}
                className="mb-3 froget-password"
              >
                تسجيل
              </a>
            </div>
          </div>
        </Form.Group>
      </Form>
    </>
  );
}

export default Login;
