import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import Text from '../general/text';
import { toAbsoluteUrl } from '../../AssetHelpers'


function OrderMenu(props) {
    const { image, name, qty, price } = props
    const img_not_found = toAbsoluteUrl("/media/img/not_found.png")

    return (
        <Row className='align-items-center mb-4'>
            <Col xs={6}>
                <div className='img-cart'><img src={image ? image : img_not_found} className="img-fluid" alt="..." /></div>
            </Col>
            <Col xs={6}>
                <Text text={name} />
                <p className='color-white mb-2'>{price}$</p>
                <div className='d-flex'>
                    <Text text="العدد" /> <span className='color-white px-1'> : </span>
                    <Text text={qty} />
                </div>
            </Col>
        </Row>
    );
}

export default OrderMenu;
