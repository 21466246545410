
import React from 'react';
import { Row, Col } from 'reactstrap';
import Text from '../general/text';

function BannerWithText(props) {
    const {image , description } = props
    return (
        <div className='padding-top'>
            <Row className='mx-0 px-0 bannerWithText' style={{ backgroundImage: `url(${image})` }}>
                <Col md={6}>
                    <Text type={'tagHtml'} text={description}/>
                </Col>
            </Row>
        </div>
    );
}

export default BannerWithText;
