
import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Container } from 'reactstrap';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
function BreadcrumbComp(props) {

    return (
        <Container className='breadcrumb-container'>
            <Breadcrumb listTag="div" tag="nav" className='float-sm-right'>
                <BreadcrumbItem>
                    <Link to='/'>الرئيسية</Link>
                </BreadcrumbItem>
                <ArrowBackIosIcon className='breadcrumb-MuiSvgIcon-root' />
                {props?.breadcrumData.map((data, index) => {
                    return (
                        data.link != '' ?
                            <>
                                {index > '0' && <ArrowBackIosIcon className='breadcrumb-MuiSvgIcon-root' />}
                                <BreadcrumbItem >
                                    <Link to={'/'+data.link}>{data.label}</Link>
                                </BreadcrumbItem>
                            </>
                            :
                            <>
                                {index > '0' && <ArrowBackIosIcon className='breadcrumb-MuiSvgIcon-root' />}
                                <BreadcrumbItem active>{data.label}</BreadcrumbItem>
                            </>
                    )
                })}
            </Breadcrumb>
        </Container>
    );
}

export default BreadcrumbComp;
