import React, { useState } from "react";
import "../App.css";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import {
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavItem,
  Nav,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { callApiWithGenerateToken } from "../api/api";
import MainModal from "./login/mainModal";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Modal, Button } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
var config = require("../Config2.js");

function Navbarr(props) {
  let t = localStorage.getItem("token");
  let txt = "";
  let name = localStorage.getItem("fname");
  if (name) {
    const cut = (input) => {
      if (input.length > 5) {
        return input.substring(0, 10) + "...";
      }
      return input;
    };
    txt = cut(name);
  }
  const navigate = useNavigate();
  const { cart } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [token, setToken] = useState(t);
  const [curintPageActive, setCurintPageActive] = useState("");
  const [headerlable, setHeaderlable] = useState("");
  const [show, setShow] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let location = useLocation();

  function noRefCheck() {
    setIsOpen(!isOpen);
  }
  function signOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("phone");
    localStorage.removeItem("email");
    localStorage.removeItem("fname");
    localStorage.removeItem("lname");
    localStorage.removeItem("cart");
    localStorage.removeItem("orderId")
    localStorage.removeItem("cuppon");
    localStorage.removeItem("cType");
    localStorage.removeItem("country");
    localStorage.removeItem("address");
    localStorage.removeItem("zipcode");

    setToken(null);
    window.location.reload();
  }

  React.useEffect(() => {
    getData();
    setCurintPageActive(location.pathname);
    setIsOpen(false);
  }, [location]);

  const myAccNavigator = () => {
    navigate("MyAccount");
  };
  const getData = async () => {
    var data = await callApiWithGenerateToken(
      config.DOMAIN,
      { type: "getHeaderMenu", lang: 1 },
      "POST"
    );
    setHeaderlable(data.data);
  };

  return (
    <div className="header-absolute">
      <Container class="Home-welcome-section container">
        <Navbar expand="md" dark>
          <NavbarBrand href="/" className="logo-header">
            <img className="nashmi-image img-fluid" src="https://iit.hojacrm.com/react-nashmi/api/downloadFile.php?taskId=914&imageName=ESPORT_1664870872.png" />
          </NavbarBrand>
          <NavbarToggler
            className="header-color-responsev"
            onClick={() => noRefCheck()}
          />
          <Collapse
            isOpen={isOpen}
            navbar
            className="justify-content-lg-between"
          >
            <Nav className="header-color-responsev header-hover" navbar>
              {headerlable
                ? headerlable.map((item, index) => {
                  return (
                    <NavItem
                      className={
                        curintPageActive == item.link
                          ? "active-header-li header-font"
                          : "inactive-header-li header-font"
                      }
                    >
                      <Link
                        className={
                          curintPageActive == item.link
                            ? "active-header"
                            : "inactive-header"
                        }
                        to={item.link}
                      >
                        {item.name}
                      </Link>
                    </NavItem>
                  );
                })
                : null}

              <Modal
                dialogClassName="modal-90w"
                show={show}
                onHide={handleClose}
              >
                <MainModal state={handleClose} />
              </Modal>
            </Nav>
            <Nav className="header-color-responsev header-hover" navbar>
              {!token ? (
                <NavItem
                  className={
                    curintPageActive == "MyAccount"
                      ? "active-header-li header-font"
                      : "inactive-header-li header-font"
                  }
                >
                  <Link
                    className={
                      curintPageActive ? "active-header" : "inactive-header"
                    }
                    to={"MyAccount"}
                  >
                    <AccountCircleIcon onClick={handleShow} />
                  </Link>
                </NavItem>
              ) : (
                <NavItem
                  className={
                    curintPageActive == "/MyAccount"
                      ? "active-header"
                      : "inactive-header"
                  }
                >
                  <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle caret style={{ backgroundColor: 'transparent', border: "0" }}>
                      <AccountCircleIcon /> {txt}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem className="myacc-dropdown" onClick={myAccNavigator}>
                        حسابي
                      </DropdownItem>
                      <DropdownItem className="myacc-dropdown" onClick={signOut}> تسجيل خروج</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>
              )}
              <NavItem
                className={
                  curintPageActive == "Cart"
                    ? "active-header-li header-font"
                    : "inactive-header-li header-font"
                }
              >
                <Link
                  className={
                    curintPageActive == "Cart"
                      ? "active-header"
                      : "inactive-header"
                  }
                  to={"/Cart"}
                >
                  <ShoppingCartOutlinedIcon className="color-white" />
                  <sup>
                    {cart != undefined && cart != null && cart != ""
                      ? cart.itemInCart.length
                      : 0}
                  </sup>
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </div>
  );
}

export default Navbarr;
