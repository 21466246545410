import React, { useCallback, useState, useEffect } from 'react';
import { Col, Container, Row } from "reactstrap";
import { PhoneAndroid, ExpandMore, ExpandLess } from '@mui/icons-material';
import { toAbsoluteUrl } from "../../AssetHelpers";
import Slider from "react-slick";
function GamesBoxesGeneral(props) {
    const testCard = toAbsoluteUrl("media/img/Card.svg")
    return (
        <Row>
            <Col xs={12}className="Col-Games">
                <img src={testCard} className="img-fluid" />
            </Col>
        </Row>

    );
}

export default GamesBoxesGeneral;
