import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
import Text from '../general/text';

function OrderSummary(props) {
const { cart, getData, tax} = props
const cupon = localStorage.getItem("cuppon");
const type = localStorage.getItem("cType");
    return (


        <Card className='mb-4'>
            <CardBody className='col-md-12'>
                <Text text="ملخص الطلب" />
                <Row className=''>
                    <Col xs={6}><Text text={'المجموع'} /></Col>
                    <Col xs={6} className='text-start fst-italic color-white'>${cart.subTotal}</Col>
                    <Col xs={6}><Text text={'الضريبة %'+tax} /></Col>
                    <Col xs={6} className='text-start fst-italic color-white'>${cart.totalWithTax}</Col>
                    {cupon &&
                    <Fragment>
                    <Col xs={6}><Text text={'الخصم '+ `${type == 0 ?'%'+cupon :"" }`} /></Col>
                    <Col xs={6} className='text-start fst-italic color-white'>${cart.discount}</Col>
                    </Fragment>
                    }
                    <Col xs={12} className="divider"></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={6} className={'mb-2'}><Text text={'المجموع الكلي'} /></Col>
                    <Col xs={6} className='text-start mb-2 fst-italic  color-white'>${cart.allTotal}</Col>
                    <Col xs={12}><Link className='btn btn-white w-100 px-2' onClick={getData}>الاستمرار للدفع</Link></Col>
                </Row>
            </CardBody>
        </Card>


    );
}

export default OrderSummary;
