
import React from 'react';
import { Row, Col } from 'reactstrap';

function Text(props) {
    const { text, type, bold, numberLetter,line ,img} = props

    return (
        <Row>
            <Col md={12}>
                {type == 'tagHtml' ? 
                 <div className={bold == '700' ? `body-text fw-bold ${line} ${img}` :`body-text ${line} ${img}`}dangerouslySetInnerHTML={{__html: props.text.substring(0, numberLetter)}}/> 
                 :
                 <div className={bold == '700' ? `body-text fw-bold ${line} ${img}` :`body-text ${line} ${img}`}>{text}</div>
            }
            </Col>
        </Row>
    );
}

export default Text; 
