import React, { useState } from "react";
import { Row, Col, Container, NavLink } from 'reactstrap';
import { callApiWithGenerateToken } from '../api/api'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

var config = require('../Config2.js')

function Footer() {

  const [footerlable, setFooterlable] = useState('');
  React.useEffect(() => {
    getData()
  }, []);

  const getData = async () => {
    var data = await callApiWithGenerateToken(config.DOMAIN, { type: 'getFooterMenu', lang: 1 }, 'POST');
    setFooterlable(data.data)
  }

  return (
    <div className='main-footer'>
      <Container>
        <Row>
          <Col lg={2} md={3}>
            <Link className="footer-labils-box" to="/">
            <img className="nashmi-image img-fluid" src="https://iit.hojacrm.com/react-nashmi/api/downloadFile.php?taskId=914&imageName=ESPORT_1664870872.png" />
            </Link>
          </Col>
          <Col lg={10} xl={6} md={9}>
            <Row>
            {footerlable ?
              footerlable.map((item, index) => {
                return (
                  <Col xs={12} sm={3} md={2}>
                  <Link className="footer-labils-box" to={item.link}>
                    <p className='footer-labils'>{item.name}</p>
                  </Link>
                  </Col>
                )
              }) : null}
              </Row>
          </Col>
        </Row>

        <hr className='line-footer'></hr>
        <Row xs="2">

          <Col md='12' xs='12' >
            <p className='text-align-center'>esport.jo . كل الحقوق محفوظة {new Date().getFullYear()} ©</p>
          </Col>
        </Row>
      </Container>

    </div>

  );
}

export default Footer;