
import {Container} from 'reactstrap';
import loadingIMG from "../loadingIMG.gif"

function Loading() {
  return (
  <div >
        <Container className='text-center'>
           <img className='loading-home-start' src={loadingIMG}  />
        </Container>
  </div> 
  );
}

export default Loading;