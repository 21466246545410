
import { Chat } from '@mui/icons-material';

import { BrowserRouter as Router, Link } from "react-router-dom";

import { Row, Col, Container } from 'reactstrap';
import page33img from "../../page33img.png"
import Text from '../general/text';

function news(props) {
  return (

    <div style={{ backgroundColor: '#282c34', backgroundImage: `url(${page33img})` }} className="index-bg-img">
      <div className='gradient-top'></div>
      <Container>
        <Row className='justify-content-xl-end index-news-h'>
          <Col md={10} xxl={7} className="offset-xl-1">

            {/* News */}

            <Row className='mb-5'>
              <Col md={12}>
                <div className='d-flex align-items-center'>
                  <Chat className='icon-page PhonelinkRingIcon icon-size' />
                  <div className='title-page px-2'>الاخبار</div>
                </div>
              </Col>
            </Row>

            {/* Start Content */}
            <Row>
              <Col md={12}>
                <Text type={'tagHtml'}text={props.data.moreFields['1_Text_Area_1_3']} />
                <Text type={'tagHtml'}  img={'newsImag'} text={props.data.description} numberLetter={1455} />
                <div className="mt-5">
                  <Link to='/News' exact={true}>
                    <button className='btn btn-white'>
                      عرض المزيد
                    </button>
                  </Link>
                </div>
              </Col>
            </Row>

            {/* end Content */}
          </Col>
        </Row>

      </Container>
      <div className='gradient'></div>
    </div>
  );
}

export default news;