import React, { useCallback, useState, useEffect } from 'react';
import mainBkgProductP from "../mainBkgProductP.png"
import { callApiWithGenerateToken } from '../api/api'
import { Row, Col, Container } from 'reactstrap';
import BreadcrumbComp from '../component/general/breadcrumb';
import Banner from '../component/general/banner';
import MyAccountVerticalTabs from '../component/general/MyAccounVerticalTabs';
import Title from '../component/general/title';
import Loading from '../component/Loading';
import { toAbsoluteUrl } from '../AssetHelpers'


var config = require('../Config2.js')



export default function MyAccount() {
    
    const imgBanner = toAbsoluteUrl("/media/img/bannerAllBlog.png")

    const [bannerImg, setBannerImg] = useState('');
    const [category, setCategory] = useState('');
    const [items, setItems] = useState('');
    const [pageActive, setPageActive] = useState(1);
    const [parent, setParent] = useState(0);
    const [flagButton, setFlagButton] = useState(0);
    const [loading, setLoading] = useState(0)
    const [breadcrumArr, setBreadcrumArr] = useState([
      {
        label: 'حسابي',
        link: ''
      },
    ])
    const logResult = useCallback(() => {
      return 2 + 2;
    }, []); //logResult is memoized now.
  
  
  
  

  
    // can u check this plz ! 
    
  
  
    return (
      <div className='backgcolor-Appcolor'>
        <div className='Home-Top' style={{ backgroundSize: 'cover', backgroundImage: `url(${mainBkgProductP})` }}>
          {pageActive ?
            <>
              <div className='position-relative'>
                <Banner img={bannerImg?.image ? bannerImg.image : imgBanner} />
                <BreadcrumbComp breadcrumData={breadcrumArr} />
                <div className='gradient-2'></div>
              </div>
              <Container>
                <div className="mb-4">
                  <Title title={'حسابي'} />
                </div>
                <Row>
                  <Col md={12}>
                    <MyAccountVerticalTabs loading={loading} tab={category} items={items}   flagButton={flagButton} page={pageActive} pageName={'blog'} />
                  </Col>
                </Row>
              </Container>
            </>
            :
            <Loading />
          }
        </div>
      </div>
    );
  }