import { Button } from 'react-bootstrap'
import React, {useState} from 'react';
import googleLogo from '../../google-logo.png';
import facebookLogo from '../../facebook-logo.png';


function LoginSocial(state) { 
 
    return (
        <>
           <div className='login-with-gogal'>
                        <p >سجل الدخول لحسابك</p>
                        <div className='div-login-button'>
                            <Button variant="" className='login-button ' type="submit">
                                <img src={googleLogo} alt='google logo' /><span className='me-2'> عن طريق جوجل</span>
                            </Button>
                            <Button variant="" className='login-button' type="submit">
                              <img src={facebookLogo} alt='facebook logo' /><span className='me-2'> عن طريق فيس بوك</span>
                            </Button>
                        </div>
                        <div class="divider"><span></span><span>او</span><span></span></div>
                    </div>
        </>
    );
}

export default LoginSocial;