import React, { useState } from "react";
import Text from "../general/text";
import { toAbsoluteUrl } from '../../AssetHelpers'
import { Col, Row } from "reactstrap";
import Title from "../general/title";
import Slider from "react-slick";

const BannerSlider = () => {
    const index_banner = toAbsoluteUrl("/media/img/index-banner.png")
    const settings = {
        dots: true,
        infinite: true,
        nav:false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        rtl: true,
    };
    return (
        <Slider {...settings} className="slider-banner">
            <div>
                <div className="bg-banner bg-image bg-gradient-color" style={{ backgroundImage: `url(${index_banner})` }}>
                    <Row className="align-items-center h-100">
                        <Col lg={8} xxl={5} className="px-5">
                            <Title title="انضم إلينا" />
                            <Text text="نالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية " />
                            <button className="btn btn-gradinet w-50">البدء</button>
                        </Col>
                    </Row>
                </div>
            </div>
            <div>
                <div className="bg-banner bg-image bg-gradient-color" style={{ backgroundImage: `url(${index_banner})` }}>
                    <Row className="align-items-center h-100">
                        <Col lg={6} xl={4} className="mx-5">
                            <Title title="انضم إلينا" />
                            <Text text="نالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية " />
                            <button className="btn btn-gradinet w-50">البدء</button>
                        </Col>
                    </Row>
                </div>
            </div>
            <div>
                <div className="bg-banner bg-image bg-gradient-color" style={{ backgroundImage: `url(${index_banner})` }}>
                    <Row className="align-items-center h-100">
                        <Col lg={8} xxl={5} className="px-5">
                            <Title title="انضم إلينا" />
                            <Text text="نالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية " />
                            <button className="btn btn-gradinet w-50">البدء</button>
                        </Col>
                    </Row>
                </div>
            </div>
            
        </Slider>

    )

}

export default BannerSlider
