import CloseButton from 'react-bootstrap/CloseButton';
import Login from './login';
import SignUp from './signUp';
import ForgetPassword from './forgetPassword';
import CheckCode from './checkCode';
import ResetPassword from './resetPassword';
import ConfirmPassword from './confirmMessage';
import { useState } from 'react';


function MainModal({state}) {
    
    const [showPage, setShowPage] = useState(1);

    const changeShowPage = (params) =>{ 
        setShowPage(params);}
    const data = {
        changeShowPage
    }

    return (
        <>
            <div className='mainLogiDiv '>
                <div className='rightLoginDiv '>
                <CloseButton variant="white" onClick={state} />
                <div className='text-right-login'>
                    <h1 >اقوى البطولات</h1>
                    <h2 className='text-image'>اجمع فريقك الأن</h2>
                    </div>
                </div>
               { (showPage == 1) &&<Login changeShowPage={changeShowPage} />}
               { (showPage == 2) &&<SignUp changeShowPage={changeShowPage}/>}
               { (showPage == 3) &&<ForgetPassword changeShowPage={changeShowPage}/>}
               { (showPage == 4) &&<CheckCode changeShowPage={changeShowPage}/>} 
               { (showPage == 5) &&<ResetPassword changeShowPage={changeShowPage} close={state}/>} 
               { (showPage == 6) &&<ConfirmPassword changeShowPage={changeShowPage} close={state} />} 
            </div>
        </>
    );
}

export default MainModal;