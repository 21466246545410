import React, { useEffect, useState, useCallback } from 'react';
import Banner from '../component/general/banner';
import GoalUs from '../component/aboutUs/goalUs';
import BannerWithText from '../component/aboutUs/bannerWithText';
import OurVision from '../component/aboutUs/ourVision';
import { toAbsoluteUrl } from '../AssetHelpers'
import VideoComponent from '../component/home/video'
import Partners from '../component/aboutUs/partners';
import { callApiWithGenerateToken } from '../api/api'
import Loading from '../component/Loading';
import { Row, Col, Container } from 'reactstrap';
import { Contacts } from '@mui/icons-material';

var config = require('../Config2.js')

function AboutUs() {
  const [info, setInfo] = useState('')
  const img = toAbsoluteUrl("/media/img/bg-about.png")
  const [banner, setBanner] = useState([])
  const [goal, setGaol] = useState([{
    child: []
  }
  ])
  const [bannerWithText, setBannerWithText] = useState([]);
  const [vision, setVision] = useState([]);
  const [video, setVideo] = useState([]);
  const [poster, setPoster] = useState([]);

  const [partners, setPartnersData] = useState([{
    child: []
  }
  ])


  const logResult = useCallback(() => {
    return 2 + 2;
  }, []); //logResult is memoized now.

  useEffect(() => {
    getData()
  }, [logResult]);


  const getData = async () => {
    var aboutData = await callApiWithGenerateToken(config.DOMAIN, { type: 'getAboutusPageData', lang: 1 }, 'POST');

    if (aboutData) {
      setInfo(aboutData.data)
      setBanner(aboutData.data[0])
      setGaol(aboutData.data[1], { child: aboutData.data[1]['child'] })
      setBannerWithText(aboutData.data[2])
      setVision(aboutData.data[3])
      setVideo(aboutData.data[4].moreFields['1_Text_1_6'])
      setPartnersData(aboutData.data[5], { child: aboutData.data[5]['child'] })
      setPoster(aboutData.data[4].image)

    }
  }

  return (
    <div>
      <div className='bg-img about-page' style={{ backgroundImage: `url(${img})` }}>

        {info ?
          <>
            <Banner img={banner['image']} />
            <Container className='position-relative top-title'>
              <div className='d-flex align-items-center'>
                <Contacts className='icon-page' />
                <div className='title-page px-2'>من نحن</div>
              </div>
            </Container>
            <GoalUs childGoal={goal.child} goal={goal} />
            <BannerWithText description={bannerWithText['description']} image={bannerWithText['image']} />
            <OurVision image={vision['image']} title={vision['name']} description={vision['description']} />
            <VideoComponent data={video} image={poster} />
            <Partners title={partners['name']} partners={partners.child} />
          </>
          :
          <Loading />
        }
      </div>
    </div>
  );
}

export default AboutUs;
