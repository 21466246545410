
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Text from '../general/text';
import Title from '../general/title';

function OurVision(props) {
    const { image, title, description } = props
    return (
        <div className='bg-about-vision' style={{ backgroundImage: `url(${image})` }}>
            <Container>
                <Row className='mx-0 px-0'>
                    <Col lg={8} xl={9}>
                        <Title title={title} />
                        <Text type={'tagHtml'} text={description} />
                    </Col>
                </Row>
            </Container>
        </div>
    );

}

export default OurVision;
